import React, {useEffect} from "react";
import { zl } from "../../../functions/utilities.js";
import { translate, rodzaj_rozliczenia } from "../../../functions/dictionary.js";
import { podstawa } from '../../../functions/utilities.js';

const MieszkaniecWydrukStawki = props => {

    const { tablicaCzynszeAkt, stawki_w } = props;
    let i = 1;

    const tabela = (stawki_w) => {
        const elements = [];
        
        if (stawki_w) {
            const hasOwn = Object.prototype.hasOwnProperty; 
            for (const key in stawki_w) {
                if (hasOwn.call(stawki_w, key) && !key.startsWith('czy_') && !key.endsWith('_rodzaj')) {
                    const rodzajKey = `${key}_rodzaj`;
                    const czyKey = `czy_${key}`;
                    const cena = stawki_w[key];
                    const rodzaj = stawki_w[rodzajKey];
                    const czyUwzgledniany = stawki_w[czyKey] && !isNaN(tablicaCzynszeAkt[key]);

                    const podstawa_ = rodzaj_rozliczenia(rodzaj, key) == "opłata stała" ? "1" :  podstawa(tablicaCzynszeAkt, key);

                    if (czyUwzgledniany && cena > 0 && tablicaCzynszeAkt[key] >= 0) {
                        elements.push(
                            <tr key={key}>
                                <td 
                                    style={{
                                        borderLeft: '1px solid black', 
                                        borderRight: '1px solid black',
                                        padding: '1mm 2mm',
                                        textAlign: 'center',
                                    }}
                                >    
                                    {i++}                                     
                                </td>
                                <td 
                                    style={{
                                        borderLeft: '1px solid black', 
                                        borderRight: '1px solid black',
                                        padding: '1mm 2mm',
                                    }}
                                >
                                    {translate(key)}                        
                                </td>
                                <td 
                                    style={{
                                        borderLeft: '1px solid black', 
                                        borderRight: '1px solid black',
                                        padding: '1mm 2mm',
                                        textAlign: 'center',
                                    }}
                                >
                                    {rodzaj_rozliczenia(rodzaj, key)}       
                                </td>
                                <td 
                                    style={{
                                        borderLeft: '1px solid black', 
                                        borderRight: '1px solid black',
                                        padding: '1mm 2mm',
                                        textAlign: 'center',
                                    }}
                                >
                                    { isNaN(podstawa_) ? 0 : podstawa_ }
                                </td>
                                <td 
                                    style={{
                                        borderLeft: '1px solid black', 
                                        borderRight: '1px solid black',
                                        padding: '1mm 2mm',
                                        textAlign: 'center',
                                    }}
                                >
                                    {zl(cena)}    
                                </td>
                                <td
                                    style={{
                                        borderLeft: '1px solid black', 
                                        borderRight: '1px solid black',
                                        padding: '1mm 2mm',
                                        textAlign: 'right',
                                    }}
                                >
                                    {zl(tablicaCzynszeAkt[key])}            
                                </td>
                            </tr>
                        );
                    }
                }
            }
            return elements;
            
        } else return ""
        
    };

    return (
        <>
            { tabela(stawki_w) }
        </>
    )
}

export default MieszkaniecWydrukStawki;