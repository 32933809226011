import React from 'react';


const NotyHistoriaTHead = () => {
    return (
        <thead>
            <tr>
                <th>
                    Mieszkaniec
                </th>
                <th>
                    Mieszkanie
                </th>
                <th>
                    Miesiąc
                </th>
                <th>
                    Podgląd
                </th>
                <th> E-mail</th>
            </tr>
        </thead>
    )
}

export default NotyHistoriaTHead;