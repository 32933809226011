import React from "react";

const PrzegladGlosy = props => {

    const { activeCard, tablicaMieszkancyUdzialy } = props;

    const pustaKarta = (
        <div className="glosy ramka ramka-border-shadow ramka-wnetrze-ramki">
            <p className="numer">Wybierz kartę, by wyświetlić szczegóły.</p>
        </div>
    )

    const karta = (
        <div className="glosy ramka ramka-border-shadow ramka-wnetrze-ramki">
            <table>
                <thead>
                    <tr>
                        <th>
                            Mieszkaniec
                        </th>
                        <th>
                            Czy odp 
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tablicaMieszkancyUdzialy?.map( (tab, i) => (
                            <tr key={i}>
                                <td>
                                    {`${tab.imie_nazwisko}` }
                                </td>
                                <td>
                                    {tab.czyOdp == 1 ? "Tak" : "Nie"}
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )

    return (
        <div
            className="przegladListaSzczegoly ramka-br-light"
        >
            {activeCard ? karta : pustaKarta}
        </div>
    )
}

export default PrzegladGlosy;