import React from "react";

const MieszkaniecWydrukTHead = () => {

    return (
        <thead>
            <tr>
                <th style={{border: '1px solid black', padding: '1mm 2mm'}}>    Lp.             </th>
                <th style={{border: '1px solid black', padding: '1mm 2mm'}}>    Tytył zaliczki  </th>
                <th style={{border: '1px solid black', padding: '1mm 2mm'}}>    j.m.            </th>
                <th style={{border: '1px solid black', padding: '1mm 2mm'}}>    ilość           </th>
                <th style={{border: '1px solid black', padding: '1mm 2mm'}}>    cena j.         </th>
                <th style={{border: '1px solid black', padding: '1mm 2mm'}}>    Kwota zaliczki  </th>
            </tr>
        </thead>        
    )
}

export default MieszkaniecWydrukTHead;