import React, { useEffect, useState, useRef } from 'react';

import ZbiorowyWydrukA4 from './ZbiorowyWydrukA4';
import ZbiorowyWydrukButton from './ZbiorowyWydrukButton';


const ZbiorowyWydrukModal = props => {

    const { setShowPrint, listaMsc, wspolnota, idw } = props;
    const componentRef = useRef();
    
    const handleShowPrint = () => setShowPrint(prevState => !prevState);

    return (
        <div 
            className='rozliczenieWydruk'
            onClick={handleShowPrint}
        >
            <div className='modal'>
                <ZbiorowyWydrukButton
                    componentRef={componentRef} 
                    handleShowPrint={handleShowPrint}
                />
                <ZbiorowyWydrukA4
                    componentRef={componentRef} 
                    wspolnota={wspolnota}
                    listaMsc={listaMsc}
                    idw={idw}
                />
                
            </div>
            
        </div>
    )
}

export default ZbiorowyWydrukModal;