import React, { useEffect, useState, useRef } from 'react';

import RozliczenieWydrukA4 from './RozliczenieWydrukA4';
import RozliczenieWydrukButton from './RozliczenieWydrukButton';



const RozliczenieWydrukModal = props => {

    const { showPrint, handleShowPrint, wspolnota, miesiac, stawki_w, stawkiJednorazowe_w, czynszeMsc,
        idw, lokale, ulice, grupy, udzialyKontaAkt, przelewyWspolnota, czynszeWspolnota, mieszkancy
     } = props;
    const componentRef = useRef();
    
    return (
        <div 
            className='rozliczenieWydruk'
            onClick={handleShowPrint}
        >
            <div className='modal'>
                <RozliczenieWydrukButton
                    componentRef={componentRef} 
                    handleShowPrint={handleShowPrint}
                />
                <RozliczenieWydrukA4
                    componentRef={componentRef} 
                    wspolnota={wspolnota}
                    miesiac={miesiac}
                    stawki_w={stawki_w}
                    stawkiJednorazowe_w={stawkiJednorazowe_w}
                    czynszeMsc={czynszeMsc}
                    idw={idw}
                    lokale={lokale}
                    ulice={ulice}
                    grupy={grupy}
                    udzialyKontaAkt={udzialyKontaAkt}
                    przelewyWspolnota={przelewyWspolnota}
                    czynszeWspolnota={czynszeWspolnota}
                    mieszkancy={mieszkancy}
                />
                
            </div>
            
        </div>
    )
}

export default RozliczenieWydrukModal;