import React from "react";

const WspolnotaRaportZbiorowyTHead = ( props ) => {

    const { listaMsc } = props;

    const kolumnyMiesiace = listaMsc?.map( (e, i) => {
        return (
            <>
                <td>
                    należności
                </td>
                <td>
                    wpłaty
                </td>
                <td>
                    saldo
                </td>
            </>
        )

    })
    
    const thDaty = listaMsc?.map( (e,i) => {
        return (
            <th colSpan={3} style={{textAlign: "center"}}>
                {e}
            </th>
        )
    })

    return (
        <thead>
            <tr key={-1}>
                <th rowSpan={2} style={{border: "1px solid black"}}>
                    Mieszkaniec
                </th>
                <th rowSpan={2} style={{border: "1px solid black"}}>
                    Adres
                </th>
                {
                    <>
                        {thDaty}
                        <th colSpan={3} style={{textAlign: "center"}}>
                            Podsumowanie
                        </th>
                    </>
                }
            </tr>
            {
                <tr key={0}>
                    {kolumnyMiesiace}
                    <td>
                        należność razem
                    </td>
                    <td>
                        wpłaty razem
                    </td>
                    <td>
                        saldo razem
                    </td>
                </tr>
            }
        </thead>
    ) 

}

export default WspolnotaRaportZbiorowyTHead;