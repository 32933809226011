import React from "react";
import { useLocation } from 'react-router-dom';
import { zl, isNumber } from "../../../functions/utilities";
import { mm_rrrr_to_date } from "../../../functions/dataFormat";

const ZbiorowyWydrukTFoot = ( props ) => {

    const { listaMsc } = props;
    
    const location = useLocation();
    const { state } = location;

    const przelewyWspolnota = state?.from?.tablicaPrzelewy;
    const czynszeWspolnota = state?.from?.tablicaCzynsze;

    const sumujNaleznosciMsc = (msc) => {
        let suma = 0;
        czynszeWspolnota?.forEach( czynsz => {
            czynsz?.grupy_udzial_msc?.forEach( grupa => {
                const mscTemp = grupa?.temp_msc?.find( g => g?.msc === msc)
                const lastTemp = grupa?.temp_msc[grupa?.temp_msc?.length - 1]
                const czynszTemp = mscTemp === undefined && mm_rrrr_to_date(msc) >= mm_rrrr_to_date(grupa?.temp_msc[grupa?.temp_msc?.length - 1].msc) ? lastTemp : mscTemp;
                
                        suma += isNumber(czynszTemp?.czynsz_na_msc) && (czynszTemp?.czynsz_na_msc > 0 || czynszTemp?.czynsz_na_msc == 0 || czynszTemp?.czynsz_na_msc < 0 ) ? czynszTemp?.czynsz_na_msc : 0;

            })
        })
        return suma;
    }

    const sumujWplatyMsc = (msc) => {
        let suma = 0;
        przelewyWspolnota?.forEach(przelew => {
            przelew?.przelewy?.forEach( p => {
                if (p?.msc === msc)
                    suma += p?.kwota;
            })
        })
        return suma;
    }

    const podsumowanieRok = listaMsc?.map( msc => (
        <>
            <td style={{ whiteSpace: "nowrap", border: "1px solid black", padding: "1px 2px" }}>
                {zl(sumujNaleznosciMsc(msc))}
            </td>
            <td style={{whiteSpace: "nowrap", border: "1px solid black", padding: "1px 2px" }}>
                {zl(sumujWplatyMsc(msc))}
            </td>
            <td style={{fontWeight: "600", whiteSpace: "nowrap", border: "1px solid black", padding: "1px 2px" }}>
                {zl( sumujWplatyMsc(msc) - sumujNaleznosciMsc(msc) )}
            </td>
        </>
    ))

    const podsumowanieRokTotal = () => {
        let naleznosci = 0, wplaty = 0;
        
        listaMsc?.forEach( m => {
            naleznosci += sumujNaleznosciMsc(m);
            wplaty += sumujWplatyMsc(m)
        });

        return (
            <>
                <td style={{whiteSpace: "nowrap", border: "1px solid black", padding: "1px 2px" }}>
                    {zl(naleznosci)}
                </td>
                <td style={{whiteSpace: "nowrap", border: "1px solid black", padding: "1px 2px" }}>
                    {zl(wplaty)}
                </td>
                <td style={{fontWeight: "600", whiteSpace: "nowrap", border: "1px solid black", padding: "1px 2px" }}>
                    {zl( wplaty - naleznosci)}                    
                </td>
            </>
        )
    }

    const podsumowanieFooter = (
        <tr>
            <td colSpan={2} style={{border: "1px solid black"}}>

            </td>
            {podsumowanieRok}
            {podsumowanieRokTotal()}
        </tr>
    )

    return (
        <tfoot>
            {podsumowanieFooter}
        </tfoot>
    ) 

}

export default ZbiorowyWydrukTFoot;