import React, { useEffect, useRef, useState } from "react";
import { zl } from "../../../functions/utilities.js";
import { translate, rodzaj_rozliczenia } from "../../../functions/dictionary.js";
import { podstawa } from '../../../functions/utilities.js';
import MieszkaniecWydrukNaglowek from "./MieszkaniecWydrukNaglowek.js";
import MieszkaniecWydrukKonto from "./MieszkaniecWydrukKonto.js";
import MieszkaniecWydrukButtons from './MieszkaniecWydrukButtons.js';
import MieszkaniecWydrukStawki from "./MieszkaniecWydrukStawki.js";
import MieszkaniecWydrukTHead from "./MieszkaniecWydrukTHead.js";

const MieszkaniecWydrukFooter = props => {

    const { tablicaCzynszeAkt } = props;

    return (
        <tr>
            <td
                style={{
                    border: '1px solid black',
                    textAlign: 'right',
                    padding: '1mm 2mm',
                    fontWeight: '600'
                }}
                colSpan={5}
            >
                Razem zaliczka:
            </td>
            <td 
                style={{
                    border: '1px solid black', 
                    padding: '1mm',
                    textAlign: 'right',
                    fontWeight: '600'
                }}
            >
                {zl(tablicaCzynszeAkt?.czynsz_na_msc)}
            </td>
        </tr>
    )
}

export default MieszkaniecWydrukFooter;