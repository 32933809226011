const initialState = {
    data: [],
  };

const glosowanieUchwalyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_GLOSOWANIE_UCHWALY':
      return { ...state, data: action.payload };
    default:
      return state;
  }
};
  
export default glosowanieUchwalyReducer;