import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import { translate } from "../../../functions/dictionary";
import { dataFormat2 } from "../../../functions/dataFormat";
import { fetchStawkiInd } from "../../../Redux/Actions";
import { isNumber } from "../../../functions/utilities";
import MieszkancyDaneEdytujGrupeStawkiIndHist from "./MieszkancyDaneEdytujGrupeStawkiIndHist";

const MieszkancyDaneEdytujGrupeStawkiInd = props => {

    const { edytowanyUdzialKonto, setEditGrupe, idw } = props;

    const dispatch = useDispatch();

    const stawki = useSelector( state => state?.stawki?.data);
    const stawkiInd = useSelector( state => state?.stawkiInd?.data);//?.filter( s => s.id_user == edytowanyUdzialKonto?.id_user && s.grupa_numer == edytowanyUdzialKonto?.grupa_numer );
    
    

    const [stawki_w, setStawki_w] = useState([]);

    useEffect( () => {
        setStawki_w( stawki?.filter( s => s?.id_wspolnoty == idw) )
    },[stawki, idw]);

    const [stawkiCzy, setStawkiCzy] = useState(null);
    const [wybraneStawki, setWybraneStawki] = useState({});
    const [dataOd, setDataOd] = useState(null);

    const [stawkiAkt, setStawkiAkt] = useState([]);
    const [stawkiIndAkt, setStawkiIndAkt] = useState([])

    function getAktualnaStawka(stawki_w) {
        if (stawki_w && stawki_w?.length > 0) {
            const dzis = new Date();
        
            const aktualneStawki = stawki_w;//?.filter(stawka => new Date(stawka.data_od) <= dzis);

            const najnowszaStawka = aktualneStawki && aktualneStawki?.length > 0 ? aktualneStawki?.reduce((najnowsza, stawka) => {
            return new Date(stawka.data_od) > new Date(najnowsza.data_od) ? stawka : najnowsza;
            }) : [];

            return najnowszaStawka;
        } else {
            return stawki_w   
        }

            
    }

    useEffect( () => {
        setStawkiAkt( getAktualnaStawka(stawki_w?.filter( s => s?.id_wspolnoty == idw)) )
    },[stawki_w, idw])

    useEffect( () => {
        setStawkiIndAkt( getAktualnaStawka(stawkiInd?.filter( s => s?.id_wspolnoty == idw && s?.id_user == edytowanyUdzialKonto?.id_user && s?.grupa_numer == edytowanyUdzialKonto?.grupa_numer)) )
    },[stawkiInd, idw, edytowanyUdzialKonto])

    useEffect( () => {
        if (stawkiIndAkt && stawkiIndAkt?.data_od != undefined)
            setDataOd(dataFormat2(stawkiIndAkt.data_od));
        else  
            setDataOd(dataFormat2(new Date()))
    },[stawkiIndAkt])

    useEffect( () => {
        const wynik = {};
        for (const klucz in stawkiAkt) {
            if (Object.prototype.hasOwnProperty.call(stawkiAkt,klucz)) {
              const czyKlucz = `czy_${klucz}`; 
              if (Object.prototype.hasOwnProperty.call(stawkiAkt,klucz) && stawkiAkt[czyKlucz] === "1") {
                wynik[klucz] = stawkiAkt[klucz];
              }
            }
        }

        for (const klucz in wynik) {
            if ( isNumber(stawkiIndAkt[klucz]) && wynik[klucz] != stawkiIndAkt[klucz]  )
                wynik[klucz] = stawkiIndAkt[klucz]
            
        }

        setStawkiCzy(wynik)
    }, [stawkiAkt, stawkiIndAkt]);

    useEffect( () => setWybraneStawki(stawkiCzy), [stawkiCzy])

    const handleChange = (value, nazwa) => setWybraneStawki((prevState) => ({...prevState, [nazwa]: parseFloat(value)})); 

    const handleSave = async () => {
        const obj = {
            id_wspolnoty: edytowanyUdzialKonto?.id_wspolnoty,
            id_user: edytowanyUdzialKonto?.id_user,
            grupa_numer: edytowanyUdzialKonto?.grupa_numer,
            data_od: dataOd,
            eksploatacja: null,
            garaz: null,
            strych: null,
            utrzymanie_czystosci: null,
            wywoz_smieci: null,
            fundusz_remontowy: null,
            konserw_domofonu: null,
            konserw_windy: null,
            opl_za_adm: null,
            ciepla_woda: null,
            zimna_woda: null,
            abonament_cw: null,
            co: null,
            energia_el_lokalu: null,
            ryczalt_gaz: null,
            zagosp_terenu: null,
            antena: null,
            komorka: null,
        };

        for (let key in stawkiCzy) {
            if (Object.prototype.hasOwnProperty.call(stawkiCzy, key) && Object.prototype.hasOwnProperty.call(wybraneStawki, key)) {
              if (stawkiCzy[key] !== wybraneStawki[key]) {
                obj[key] = wybraneStawki[key]; // Zapisz wartości z obu obiektów
              }
            }
        }

        try {
            await axios.post('/api/stawki_ind/dodaj', { obj }).then( response => {
                if (response.status === 200) 
                    dispatch(fetchStawkiInd())
                    setEditGrupe(0);    
            })
        } catch (error) {
            console.error('Błąd podczas dodawania danych:', error);
        }
        
    }
    
    const handleData = e => setDataOd(e.target.value)

    const lista = stawkiCzy ? Object.entries(stawkiCzy)?.map( ([stawka]) => (
        <div 
            className="listaIndWiersz"
            key={stawka}
        >
            <div 
                className="opis"
            >
                <label
                    htmlFor={`i_${stawka}`} 
                    className={ stawkiCzy[stawka] !== wybraneStawki[stawka] || ( isNumber(stawkiIndAkt[stawka]) && stawkiAkt[stawka] !== stawkiIndAkt[stawka]) ? "b" : "" }
                >
                    {translate(stawka)}
                </label>
            </div>
            <div 
                className="kwota"
            >
                <input
                    type="number"
                    value={wybraneStawki[stawka]}
                    onChange={(e)=>handleChange(e.target.value, stawka)}
                    id={`i_${stawka}`}
                />
            </div>
        </div>
    )) : ""

    return (
        <div className="stawkiInd">
            <h5>
                Wpisz indywidlane kwoty w tej grupie i datę obowiązywania:
            </h5>
            <input 
                type="date" 
                value={dataOd}
                onChange={handleData}
            />
            <div className="listaInd">
                { wybraneStawki && lista}
            </div>
            <button
                onClick={handleSave}
            >
                Dodaj
            </button>
            <MieszkancyDaneEdytujGrupeStawkiIndHist
                stawkiInd={stawkiInd?.filter( s => s.grupa_numer == edytowanyUdzialKonto?.grupa_numer)}
                stawkiIndAkt={stawkiIndAkt}
                setStawkiIndAkt={setStawkiIndAkt}
            />
        </div>
    )
}

export default MieszkancyDaneEdytujGrupeStawkiInd;