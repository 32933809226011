import React, {useEffect, useState } from 'react';
import { zl } from "../../../functions/utilities";

const ImportTransakcjiWgranaLista = props => {

    const { fileName, pelneDane, wspolnoty, mieszkancy, wybranaWspolnota, setWybranaWspolnota, czyWplataNaKontoGlowne, setCzyWplataNaKontoGlowne } = props;
    
    useEffect( () => {
        pelneDane?.forEach( (dana, i) => {
            if (dana.operacja !== "prowizja" && dana.id_wspolnoty === undefined)
                setCzyWplataNaKontoGlowne(true);
        })
    }, [pelneDane]);

    const selectWspolnota = (e) => setWybranaWspolnota( wspolnoty?.find( w => w?.id == e.target.value ) );

    const wybierzWspolnote = (
        czyWplataNaKontoGlowne && (
            <div>
                <p
                    style={{textDecoration: "underline"}}
                >
                    Wygląda na to, że co najmniej 1 nadawca zrobił przelew na konto główne wspólnoty. Wybierz z listy nazwę wspólnoty, której dotyczą te przelewy.
                </p>
                <select
                    value={ wybranaWspolnota?.id ?? -1 }
                    onChange={selectWspolnota} 
                >
                    <option 
                        key={-1}
                        value={null}
                    
                    >
                        (wybierz)
                    </option>
                    {
                        wspolnoty?.map( (wspolnota, i) => (
                            <option
                                key={i}
                                value={wspolnota.id}
                            >
                                {wspolnota.name}
                            </option>
                        ))
                    }
                </select>
            </div>
        )
    )


    return (
        <>
            <h5>
                { fileName !== "" ? `Nazwa pliku: ${fileName}` : ""}
            </h5>
            {wybierzWspolnote}
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Operacja</th>
                        <th
                            style={{minWidth: "80px"}}
                        >Data operacji</th>
                        <th>Nadawca</th>
                        <th>Mieszkaniec</th>
                        <th>Wspólnota</th>
                        <th>Kwota</th>
                        <th
                            style={{maxWidth: "250px"}}
                        >Tytuł</th>
                        <th>Odbiorca</th>
                    </tr>
                </thead>
                <tbody>
                    {pelneDane?.map((row, index) => (
                        <tr key={index} style={{ backgroundColor: index % 2 ? "" : "var(--podswietlenieListy)"  }}>
                            <td>{row.id_transkacji}</td>
                            <td>{row.operacja}</td>
                            <td>{row.data_operacji}</td>
                            <td>{row.nadawca_nazwa}</td>
                            <td>{row.id_user === undefined && row.operacja !== "prowizja" ? <span></span> : mieszkancy?.find( m => m.id_user == row?.id_user)?.imie_nazwisko }</td>
                            <td>{row.id_wspolnoty !== undefined ? wspolnoty?.find( w => w?.id === row?.id_wspolnoty)?.name : wybranaWspolnota?.name}</td>
                            <td
                                style={{textAlign: "right"}}
                            >
                                {zl(row.kwota)}
                            </td>
                            <td>{row.nazwa_transakcji}</td>
                            <td>{row.odbiorca_nazwa}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}

export default ImportTransakcjiWgranaLista;