import React from "react";
import '../../scss/style.css';
import '../../scss/listy.css';
import '../../scss/mieszkancy.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonDigging} from '@fortawesome/free-solid-svg-icons'

const Serwis = () => {

    return (
        <div className="mieszkaniec">
     
            <div className="serwis">
                <p>
                    <FontAwesomeIcon icon={faPersonDigging} size="2xl" />
                </p>
                <p>
                    W dniu 14.11 trwają prace serwisowe.
                </p>            
            </div>
        </div>
    )
}

export default Serwis;