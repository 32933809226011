import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { 
    fetchGlosowanieKarty,
    fetchGlosowaniePytania
} from '../../../Redux/Actions.js'; 
import { dataFormat, strefa } from "../../../functions/dataFormat.js";
import { fetchGlosowanieOdpowiedzi } from "../../../Redux/Actions.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import DialogYesNo from "../../DialogYesNo.js";


const MieszkaniecGlosowanieKarta = props => {
 
    const { glosowanieOdpowiedzi, glosowanieUchwaly, odpowiedzi, setOdpowiedzi, karta, kartaPytania, wspolnota, mieszkaniec } = props;

    const dispatch = useDispatch();

    const [czyAktywna, setCzyAktywna] = useState();
    const [open, setOpen] = useState(false);

    useEffect( () => {
        const today = new Date();
        setCzyAktywna( strefa(karta?.data_do) >= today && !glosowanieOdpowiedzi.some(obj => obj.id_karty == karta?.id && obj.id_user == mieszkaniec?.id_user) ? true : false );
    },[karta, glosowanieOdpowiedzi]);

    const handleOdp = e => {

        const newItem = {id: e.target.id, odp: e.target.value}

        setOdpowiedzi( prevItems => {
            const itemIndex = prevItems.findIndex(item => item?.id === newItem.id);
            
            if (itemIndex > -1) {
                const updatedItems = [...prevItems];
                updatedItems[itemIndex] = newItem;
                return updatedItems;
            } else {
                return [...prevItems, newItem]
            }

        });
    };

    const odp = id => {
        return (
            <select
                onChange={handleOdp}
                id={id}
            >
                <option value="0">
                    (wybierz)
                </option>
                <option value="1">
                    Za
                </option>
                <option value="2">
                    Przeciw
                </option>
            </select>
        )
    }

    const pytania = kartaPytania?.map( (pytanie, i) => (
        <div
            key={i}
            className="pytanie"
        >
            <div className="tresc">
                <span>
                    {`${i+1}. ${pytanie.tresc}`}
                </span>
                
            </div>
            <div className="odp">
                { czyAktywna ? odp(pytanie.id) : ""}
            </div>
        </div>
    ))

    const pobierz = async fileName => {
        try {
            const response = await axios.post( 
                '/api/glosowanie-uchwaly/pobierz',
                { fileName: fileName }, 
                { responseType: 'blob' })
            
            if (response.statusText !== "OK") {
              throw new Error('Błąd podczas pobierania pliku PDF');
            }
      
            const url = window.URL.createObjectURL(new Blob([response.data]));
      
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // Nazwa pliku do pobrania
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          } catch (error) {
            alert(`Plik "${fileName}" nie istnieje.`)
          }
    }   

    const handleClick = () => setOpen( true );

    const handleVote = () => {
        if (kartaPytania.length > 0 && (odpowiedzi.length < kartaPytania.length || odpowiedzi.some(obj => obj.odp == 0)))
            alert("Odpowiedz na wszystkie pytania.")
        else {
            odpowiedzi.forEach( (odpowiedz, i) => {
                try {   
                    // Wysyłamy dane na serwer
                    axios.post('/api/glosowanie-odpowiedzi/dodaj', {
                        id_user: mieszkaniec.id_user,
                        id_wspolnoty: wspolnota.id,
                        id_karty: karta.id,
                        id_pytania: odpowiedz.id,
                        odp: odpowiedz.odp
                    }).then(response => {
                        if (response.status === 200) {
                            dispatch(fetchGlosowanieOdpowiedzi());
                            setCzyAktywna(false);
                        }
                    });     
                    // Po udanym dodaniu danych możesz wykonać odpowiednie akcje, np. wyczyść formularz
                    } catch (error) {
                    console.error('Błąd podczas dodawania danych:', error);
                }
            })
        }
    };    

    return (
        <div className="karta ramka ramka-border-shadow ramka-wnetrze-ramki">
            <p 
                className="header pointer"
                onClick={()=>pobierz(glosowanieUchwaly?.nazwa_pliku)}
            >
                {karta?.numer}
                <FontAwesomeIcon 
                    icon={faEye} 
                    size="xs"
                    style={{margin: "0 10px"}}
                />
            </p>
            <p className="data">{`Termin na oddanie głosu: ${dataFormat(karta.data_do)}`}</p>
            <section className="pytania">
                <p>
                    Pytania:
                </p>
                {pytania}
            </section>
            {
                czyAktywna ? (
                    <button
                        onClick={handleClick}
                    >
                        Oddaj głos
                    </button>
                ) : <></>
            }
            <DialogYesNo 
                open={open}
                setOpen={setOpen}
                message={`Czy chcesz oddać głos?`}
                f={handleVote}
                id={null}
            />
        </div>
    )
}

export default MieszkaniecGlosowanieKarta;