import React from 'react';

import { pln, slownie } from '../../functions/utilities.js';

const NotaSlownie = (props) => {

    const { kwota } = props;

    return (
        <section
            style={{
                margin: '10mm 0 0 0',
                fontSize: '13px',
                display: "flex",
                justifyContent: "space-between"
            }}
        >
            <div>
                <span
                    style={{
                        marginRight: "20px"
                    }}                        
                >
                    {`Do zapłaty: `}
                </span>
                <span
                    style={{
                        fontWeight: "600",
                        marginRight: "20px"
                    }}
                >
                    {pln(kwota)}
                </span>
            </div>
            <div>
                <span>
                    {`Słownie:  ${slownie(kwota)}`}
                </span>
            </div>
        </section>
    )
}

export default NotaSlownie;