import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserLock } from '@fortawesome/free-solid-svg-icons'

import { copyToClipboard } from '../../functions/utilities.js';

import { 
    fetchUsers
} from '../../Redux/Actions.js'; 

const MieszkancyListaLista = props => {

    const { mieszkancy, mieszkaniec, setMieszkaniec, setEdit, setEditDataOd } = props;

    const handleClick = m => {
        setMieszkaniec(m);
        setEditDataOd({id: 0, show: false, dataOd: ""})
        setEdit(false);
    }

    const users = useSelector( state => state?.users?.data );
    const dispatch = useDispatch();
    useEffect( ()=> {
        dispatch(fetchUsers());
    },[dispatch]);

    const [showPwd, setShowPwd] = useState(false);
    const handlePwd = () => setShowPwd( prevState => !prevState);

    const generateList = () => {
        const lista = mieszkancy?.map( (m, i) => {
            const user = users?.find( u => u.id == m?.id_user);
            return (
                <li key={i} className={ m?.id_user ==  mieszkaniec?.id_user ? "pointer active" : "pointer"  } onClick={()=>handleClick(m)}>
                    {
                        !showPwd ? (
                            <>
                                <div>
                                    {`(${m?.id_user}) ${m?.imie_nazwisko}`}
                                </div>
                                {
                                    m?.czy_nota == ""
                                }
                            </>

                        ) : (
                            <>
                                <div>
                                    {`(${m?.id_user}) ${m?.imie_nazwisko}`}
                                </div>
                                <div
                                    style={
                                        {"textAlign": "right", "maxWidth": "70px"}
                                    }
                                >
                                    <span 
                                        onClick={copyToClipboard}
                                    >
                                        {user?.user}
                                    </span>
                                </div>
                                <div
                                    style={
                                        {"textAlign": "right", "maxWidth": "70px"}
                                    }
                                >
                                    <span 
                                        onClick={copyToClipboard}
                                    >
                                        {user?.password}
                                    </span>
                                </div>
                            </>
                        )
                    }
                </li>
            )
        })
        return lista;
    };

    return (
        <div className="ramka" style={{backgroundColor: "var(--wnetrzeRamki)"}}>
            <ul className="lista" >
                <li>
                    <div>
                        <span>Imię i nazwisko </span>
                        <FontAwesomeIcon 
                            icon={faUserLock} 
                            className="pointer"
                            style={{"margin": "0 0 0 30px"}}
                            onClick={handlePwd}
                        />
                    </div>
                </li>
                { generateList() }
            </ul>
        </div>

    )
}

export default MieszkancyListaLista;