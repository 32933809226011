import React from "react";
import { data_z_dniem } from "../../../functions/dataFormat";

import { adresPro, findMieszkanieId } from "../../../functions/ulice";

const MieszkaniecWydrukNaglowek = props => {

    const { udzialyKonta_a, mieszkaniec, lokale, ulice, grupy_a, miesiac } = props;

    const idMieszkania = findMieszkanieId(udzialyKonta_a,grupy_a,lokale);

    const adres = adresPro(idMieszkania,lokale, ulice);
   
    return (
        <>
            <section
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: '13px'
                }}
            >
                <div>
                    <span>
                        <b>Administrator</b><br/>
                        Biuro Obsługi Nieruchomości<br/>
                        Mojsiewicz & Stanek s.c.<br/>
                        ul. Mieszka I 57/6<br/>
                        66-400 Gorzów Wielkopolski<br/>
                        t: 95 724 19 08<br/>
                        e: biuro@bonadministrator.pl
                    </span>
                </div>
                <div>
                    Gorzów Wlkp. {data_z_dniem()}
                </div>
            </section>
            <section
                style={{
                    display: 'flex',
                    justifyContent: 'right',
                    fontSize: '13px',
                    margin: '20mm 0 0 0'
                }}
            >
                <span
                    style={{
                        fontWeight: '600',
                        fontSize: '14px'
                    }}
                >
                    Sz. P.<br/>
                    {mieszkaniec?.imie_nazwisko}<br/>
                    {adres}<br/>
                    66-400 Gorzów Wlkp.

                </span>
                
            </section>
            <section
                style={{
                    margin: '10mm 0 0 0'
                }}
            >
                <span
                    style={{
                        fontSize: '13px'
                    }}
                >
                    {`Uprzejmie zawiadamiamy, iż wymiar miesięcznej zaliczki na pokrycie kosztów zarządu nieruchomością wspólną oraz zaliczek na poczet mediów i usług dodatkowych w miesiącu ${miesiac} r. wynosi:`}
                </span>
            </section>
        </>
    )
}

export default MieszkaniecWydrukNaglowek;