import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import axios from "axios";
import { translate } from "../../../functions/dictionary";
import { dataFormat2 } from "../../../functions/dataFormat";
import { fetchStawkiInd } from "../../../Redux/Actions";
import { isNumber } from "../../../functions/utilities";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import DialogYesNo from "../../DialogYesNo";

const MieszkancyDaneEdytujGrupeStawkiIndHist = props => {

    const { stawkiInd, stawkiIndAkt, setStawkiIndAkt } = props;
    const [open, setOpen] = useState(false);
    const [trashId, setTrashId] = useState(0);

    const dispatch = useDispatch();

    const loadInd = id => {
        setStawkiIndAkt( stawkiInd?.find( s => s.id == id ) )
    }

    const handleClick = (id) => {
        setTrashId(id)
        setOpen(true);   
    }

    const lista = stawkiInd?.map( (stawka, i) => {

        return (
            <li key={i}>
                <div
                    className={ stawkiIndAkt?.id == stawka.id ? "pointer b" : "pointer" }
                    onClick={()=>loadInd(stawka.id)}
                >
                    {dataFormat2(stawka?.data_od)}
                </div>
                <div>
                    <FontAwesomeIcon
                        icon={faTrash} 
                        className="pointer"
                        onClick={()=>handleClick(stawka.id)}
                    />
                </div>
            </li>
        )
    })

    async function usunStawki(id) {
        try {
            const response = await axios.post('/api/stawki_ind/usun', { id: id });
            if (response.data.success === true) {
                dispatch(fetchStawkiInd()); 
            }
        } catch (error) {
            console.error('Error sending ID to backend:', error);
        }
    }


    return (
        <div className="stawkiIndHist">
            <h5>
                Lista indywidualnych stawek:
            </h5>
            <ul className="lista">
                <li></li>
                {lista}
            </ul>
            <DialogYesNo 
                open={open}
                setOpen={setOpen}
                message={`Czy na pewno chcesz usunąć te stawki?`}
                f={usunStawki}
                id={trashId}
            />
        </div>
    )
}

export default MieszkancyDaneEdytujGrupeStawkiIndHist;