import React, { useState, useEffect } from "react";
import { adresPro, findMieszkanieId } from '../../../functions/ulice';
import { useSelector, useDispatch } from 'react-redux';
import axios from "axios";
import { fetchStawkiJednorazoweInd } from "../../../Redux/Actions";
import { dataFormat2, dataFormatMsc } from "../../../functions/dataFormat";

const WspolnotyStawkiJednorazoweGrupyForm = props => {

    const { stawka, setStawka, wspolnota, wybranyMieszkaniec, wybranaGrupa } = props;

    const dispatch = useDispatch();

    const handleMsc = e => {
        setStawka( prevState => ({
            ...prevState, 
            msc: e.target.value
        }))
    }

    const handleOpis = e => {
        setStawka( prevState => ({
            ...prevState,
            opis: e.target.value
        }))
    }

    const handleKwota = e => {
        setStawka( prevState => ({
            ...prevState,
            kwota: e.target.value
        }))
    }
    
    const handleSave = () => {
        ///valid()
        stawka["id_wspolnoty"] = wspolnota?.id;
        stawka["id_user"] = wybranyMieszkaniec;
        stawka["grupa_numer"] = wybranaGrupa;
        send(stawka)
    }

    const send = async (obj) => {
        try {
          await axios.post('/api/wspolnoty_stawki_jednorazowe_ind/dodaj', {
            obj
          }).then( response => {
            dispatch(fetchStawkiJednorazoweInd());
            setStawka( {msc: dataFormat2(new Date(new Date().setDate(1))), opis: "", kwota: 0} )
          });
        } catch (error) {
          console.error('Błąd podczas dodawania danych:', error);
        }
    };

    return (
        <div className="stawkaForm">
            <div className="wiersz">
                <label
                    htmlFor="msc"
                >
                    Miesiąc:
                </label>
                <input
                    id="msc"
                    type="date"
                    value={stawka.msc}
                    onChange={handleMsc}
                />
            </div>
            <div className="wiersz">
                <label
                    htmlFor="opis"
                >
                    Opis:
                </label>
                <input
                    id="opis"
                    type="text"
                    value={stawka.opis}
                    onChange={handleOpis}
                />
            </div>
            <div className="wiersz">
                <label
                    htmlFor="kwota"
                >
                    Kwota:
                </label>
                <input
                    id="kwota"
                    type="number"
                    value={stawka.kwota}
                    onChange={handleKwota}
                />
            </div>
            <button
                onClick={handleSave}
            >
                Zapisz
            </button>

        </div>
    )

}

export default WspolnotyStawkiJednorazoweGrupyForm;
