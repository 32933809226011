import React from "react";

const WspolnotyListaTHead = () => {

    return (
      <li>
          <div>   Nazwa</div>
          <div>   NIP</div>
          <div>   Adres</div>
          <div className="central">
            Dane</div>
          <div className="central">
              Stawki</div>
          <div className="central">
              Należności</div>
          <div className="central">
              Raport zbiorowy</div>
          <div className="central">
              Mieszkańcy</div>
        <div className="central">
              Grupy</div>
          <div className="central">
              Noty - wysyłka
          </div>
          <div className="central">
              Udziały</div>
      </li>
    )
}

export default WspolnotyListaTHead;

