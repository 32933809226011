import React, {useEffect, useState } from 'react';
import { arrayHasElement, filterKeysWithNumbers } from '../../../functions/tablice.js';

const MieszkancyRozliczenieList = ( props ) => {

    const { grupa_numer, czynszeMieszkaniec } = props;
 
    const [czynszeGrupa, setCzynszeGrupa] = useState([]);
    const [headers, setHeaders] = useState([]);

    useEffect( () => {
        if (czynszeGrupa && czynszeGrupa?.length > 0) {
            const temp = filterKeysWithNumbers(czynszeGrupa);
            const zredukowanaTablica = temp.reduce((acc, obj) => {
                Object.keys(obj).forEach(key => {
                    if (!acc.includes(key)) {
                        acc.push(key);
                    }
                });
                return acc;
            }, []);
            setHeaders(zredukowanaTablica)
        }
    },[czynszeGrupa])

    useEffect( () => setCzynszeGrupa( czynszeMieszkaniec?.find( c => c?.grupa_numer == grupa_numer)?.temp_msc ), [czynszeGrupa]);

    return (
        <thead>
            <tr>
                <td>Miesiąc</td>
                { arrayHasElement(headers,"eksploatacja") ? (<td>eks.</td>) : "" }
                { arrayHasElement(headers, "garaz") ? (<td>gar.</td>) : "" }
                { arrayHasElement(headers, "strych") ? (<td>str.</td>) : "" }
                { arrayHasElement(headers, "komorka") ? (<td>kom.</td>) : "" }
                { arrayHasElement(headers, "utrzymanie_czystosci") ? (<td>utr.</td>) : "" }
                { arrayHasElement(headers, "wywoz_smieci") ? (<td>wyw.</td>) : "" }
                { arrayHasElement(headers, "fundusz_remontowy") ? (<td>rem.</td>) : "" }
                { arrayHasElement(headers, "konserw_domofonu") ? (<td>dom.</td>) : "" }
                { arrayHasElement(headers, "konserw_windy") ? (<td>win.</td>) : "" }
                { arrayHasElement(headers, "opl_za_adm") ? (<td>adm.</td>) : "" }
                { arrayHasElement(headers, "abonament_cw") ? (<td>abo.</td>) : "" }
                { arrayHasElement(headers, "co") ? (<td>co</td>) : "" }
                { arrayHasElement(headers, "energia_el_lokalu") ? (<td>ene.</td>) : "" }
                { arrayHasElement(headers, "ryczalt_gaz") ? (<td>gaz</td>) : "" }
                { arrayHasElement(headers, "zagosp_terenu") ? (<td>zag.</td>) : "" }
                { arrayHasElement(headers, "antena") ? (<td>ant.</td>) : "" }
                { arrayHasElement(headers, "ciepla_woda") ? (<td>w. ciepła</td>) : "" }
                { arrayHasElement(headers, "zimna_woda") ? (<td>w. zimna</td>) : "" }
                { arrayHasElement(headers, "dod_1") ? (<td>dod. 1</td>) : "" }
                { arrayHasElement(headers, "dod_2") ? (<td>dod. 2</td>) : "" }
                { arrayHasElement(headers, "dod_3") ? (<td>dod. 3</td>) : "" } 
                { arrayHasElement(headers, "jednorazowaInd_kwota") ? (<td>ind.</td>) : "" }
                <td>Suma</td>
                <td>Drukuj zaliczkę za miesiąc</td>
            </tr>
        </thead>
    )
}

export default MieszkancyRozliczenieList;