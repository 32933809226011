import React from "react";
import { formatKonta } from "../../../functions/utilities.js";

const MieszkaniecWydrukKonto = props => {

    const { udzialyKonta_a } = props;

    return (
        <section
            style={{
                margin: '10mm 0 0 0'
            }}
        >
            <span
                style={{
                    fontSize: '13px'
                }}
            >
                {`Należność powyższą prosimy uregulować do 10-tego dnia każdego miesiąca dokonując wpłaty na konto `}
            </span>
            <p
                style={{
                    fontSize: '13px'
                }}
            >
                {formatKonta(udzialyKonta_a?.konto_bankowe)}
            </p>
        </section>
    )
}

export default MieszkaniecWydrukKonto;