import React, { useEffect, useState } from "react";
import { zl } from "../../../functions/utilities.js";

const MieszkaniecWydrukStawkiJednorazowe = props => {

    const { stawkiJednorazowe, miesiac, licznik } = props;

    const [stawkiJednorazowe_msc, setStawkiJednorazowe_msc] = useState([]);

    useEffect( () => {
        setStawkiJednorazowe_msc( stawkiJednorazowe?.find( s => s?.msc == miesiac) );
    }, [stawkiJednorazowe, miesiac]);
   
    const puste = Array(3).fill(null).map((_, index) => (
        <td 
            key={index}
            style={{
                borderLeft: '1px solid black', 
                borderRight: '1px solid black',
                padding: '1mm 2mm',
                textAlign: 'center',
            }}
        >    
        </td>
    ));

    const tabela2 = s => {
        let currentMaxI = licznik; // Użycie licznik jako początkowa wartość

        const rows = [];

        if (s?.dod_1_czy == 1) {
            rows.push(
                <tr key='dod_1'>
                    <td 
                        style={{
                            borderLeft: '1px solid black', 
                            borderRight: '1px solid black',
                            padding: '1mm 2mm',
                            textAlign: 'center',
                        }}
                    >    
                        { ++currentMaxI }
                    </td>
                    <td 
                        style={{
                            borderLeft: '1px solid black', 
                            borderRight: '1px solid black',
                            padding: '1mm 2mm',
                            textAlign: 'left',
                        }}
                    >    
                        { s?.dod_1_opis }
                    </td>
                    { puste }
                    <td 
                        style={{
                            borderLeft: '1px solid black', 
                            borderRight: '1px solid black',
                            padding: '1mm 2mm',
                            textAlign: 'right',
                        }}
                    >    
                        { zl(s.dod_1_kwota) }
                    </td>
                </tr>
            );
        }
        if (s?.dod_2_czy == 1) {
            rows.push(
                <tr key='dod_2'>
                    <td 
                        style={{
                            borderLeft: '1px solid black', 
                            borderRight: '1px solid black',
                            padding: '1mm 2mm',
                            textAlign: 'center',
                        }}
                    >    
                        { ++currentMaxI }
                    </td>
                    <td 
                        style={{
                            borderLeft: '1px solid black', 
                            borderRight: '1px solid black',
                            padding: '1mm 2mm',
                            textAlign: 'left',
                        }}
                    >    
                        { s?.dod_2_opis }
                    </td>
                    { puste }
                    <td 
                        style={{
                            borderLeft: '1px solid black', 
                            borderRight: '1px solid black',
                            padding: '1mm 2mm',
                            textAlign: 'right',
                        }}
                    >    
                        { zl(s.dod_2_kwota) }
                    </td>
                </tr>
            );
        }
        if (s?.dod_3_czy == 1) {
            rows.push(
                <tr key='dod_3'>
                    <td 
                        style={{
                            borderLeft: '1px solid black', 
                            borderRight: '1px solid black',
                            padding: '1mm 2mm',
                            textAlign: 'center',
                        }}
                    >    
                        { ++currentMaxI }
                    </td>
                    <td 
                        style={{
                            borderLeft: '1px solid black', 
                            borderRight: '1px solid black',
                            padding: '1mm 2mm',
                            textAlign: 'left',
                        }}
                    >    
                        { s?.dod_3_opis }
                    </td>
                    { puste }
                    <td 
                        style={{
                            borderLeft: '1px solid black', 
                            borderRight: '1px solid black',
                            padding: '1mm 2mm',
                            textAlign: 'right',
                        }}
                    >    
                        { zl(s.dod_3_kwota) }
                    </td>
                </tr>
            );
        }

        return rows;
    };

    return (
        <>
            { tabela2(stawkiJednorazowe_msc) }
        </>
    );
};

export default MieszkaniecWydrukStawkiJednorazowe;