import React, { useEffect, useState } from "react";
import axios from "axios";
import { usterki as usterkiLista } from "../../../functions/usterki";
import { motion } from 'framer-motion';
import { wstepy } from '../../../functions/motions';

const Usterka = props => {

    const { mieszkaniec, wspolnota } = props;
    const [activeCat, setActiveCat] = useState("0");
    const [activeSubCat, setActiveSubCat] = useState("0");
    const [subCats, setSubCats] = useState([]);
    const [comment, setComment] = useState("");
    const [notification, setNotification] = useState(false);

    useEffect( () =>{
        const cat = usterkiLista?.find( u => u?.value == activeCat )?.subs;
        const lista = cat?.map( (c, i) => (
            <option
                key={i}
                value={c.value}
            >
                {c.nazwa}
            </option>
        ));
        setSubCats(lista);
    },[activeCat]);

    const cats = usterkiLista?.map( (kategoria, i) => (
        <option
            key={i}
            value={kategoria.value}
        >
            {kategoria.nazwa}
        </option>
    ))

    const handleCat = e => {
        setActiveCat(e.target.value);
        setNotification(false);
    }
    const handleSubCat = e => {
        setActiveSubCat(e.target.value);
        setNotification(false);
    }
    const handleComment = e => {
        setComment(e.target.value);
        setNotification(false);
    }

    const handleSend = async e => {
        e.preventDefault();
        if (activeCat == 0)
            alert("Wybierz kategorię")
        else if (activeSubCat == 0) 
            alert("Wybierz podkategorię")
        else {
            try {   
                // Wysyłamy dane na serwer
                await axios.post('/api/usterki/dodaj', {
                    id_user: mieszkaniec?.id_user,
                    id_wspolnoty: mieszkaniec?.id_wspolnoty,
                    cat: activeCat,
                    subcat: activeSubCat,
                    comment: comment
                }).then(response => {
                    if (response.status === 200) {
                        setActiveCat("0");
                        setActiveSubCat("0");
                        setComment("");
                        sendMail();
                        setNotification(true);
                    }
                });
                // Po udanym dodaniu danych możesz wykonać odpowiednie akcje, np. wyczyść formularz
              } catch (error) {
                console.error('Błąd podczas dodawania danych:', error);
              }
        }
    }

    const sendMail = async ( ) => {
        try {
            const response = await axios.post('/api/usterki/send-mail', {
                mieszkaniec: mieszkaniec?.imie_nazwisko,
                wspolnota: wspolnota?.name,
                cat: usterkiLista?.find( u => u?.value == activeCat)?.nazwa,
                subcat: usterkiLista?.find( u => u?.value == activeCat)?.subs?.find( s => s?.value == activeSubCat)?.nazwa,
                comment: comment
            });
        
            if (response.status === 200) {
              console.log('E-mail został wysłany pomyślnie!');
            }
          } catch (error) {
            console.error('Błąd podczas wysyłania e-maila:', error);
          }
        
    }

    return (
        <motion.div
            variants={wstepy}
            initial="initial"
            animate="animate"
            className="usterka"
        >
            <h4>
                Zgłaszanie usterki
            </h4>
            <form onSubmit={handleSend}>
                <div>   
                    <label htmlFor="cat">
                        Wybierz kategorię:
                    </label>
                    <select 
                        onChange={handleCat}
                        value={activeCat}
                        id="cat"
                    >
                        {cats}
                    </select>
                </div>
                <div>
                    <label htmlFor="subCat">
                        Wybierz podkategorię:
                    </label>
                    <select
                        onChange={handleSubCat}
                        value={activeSubCat}
                        id="subCat"

                    >
                        {subCats}
                    </select>
                </div>
                <div>
                    <label htmlFor="comment">
                        Opis:
                    </label>
                    <textarea
                        cols={50}
                        rows={6}
                        id="comment"
                        value={comment}
                        onChange={handleComment}
                    />
                </div>
                <button>
                    Wyślij
                </button>
            </form>
            <span
                style={ !notification ? {display: "none" } : {} }
            >
                Zgłoszenie wysłane
            </span>
        </motion.div>
    )
}

export default Usterka;