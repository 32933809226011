import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import jsPDF from 'jspdf';
import NotaA4 from './NotaA4';
import { obliczDatyNoty } from '../../functions/dataFormat';
import { delay } from '../../functions/utilities';
import robotoFont from  "../../fonts/Roboto-Regular-normal.js";
import { fetchNoty } from '../../Redux/Actions.js';

const NotyMasowa = ( props ) => {

  const { tablicaObiektow, setWysylkaStan, wspolnota, tablicaCzynsze, lokale, setLoaderOn, activeDock, setActiveDock, mieszkancy, noty } = props;
  
  const divRefs = useRef([]);
  const [showAll, setShowAll] = useState(false);

  const dispatch = useDispatch();

  const [activeButton, setActiveButton] = useState(true);

  useEffect( () => {
    const obj = noty?.filter( n => n.msc == obliczDatyNoty()?.miesiacNaliczenia)
    
    setActiveButton( obj?.length > 0 != undefined ? false: true )
  },[noty])

  const [docksList, setDockList] = useState([]);

  const generatePDF = () => {
    const doc = new jsPDF('p', 'mm', 'a4');
    doc.addFileToVFS("Roboto-Regular.ttf", robotoFont);
    doc.addFont("Roboto-Regular.ttf", "Roboto-Regular", "normal");
    doc.setFont("Roboto-Regular");
    
    for (let i = 0; i < divRefs.current.length; i ++) {
      const { ref, refNr } = divRefs.current[i];

      const obiekt = tablicaObiektow?.find( t => t?.nota == refNr);
      console.log(obiekt)
      const msc = obliczDatyNoty()?.miesiacNaliczenia;
      const fileName = `Nota obciążeniowa - ${ obiekt?.mieszkaniec?.imie_nazwisko } - ${obiekt?.grupyNoty?.notaNr} - ${ msc }.pdf`;

      doc.html(ref, {
        callback: async (doc) => {
          const pdfBlob = doc.output('blob'); 
          const formData = new FormData();
          formData.append("file", pdfBlob, fileName);

          try {
            // Wysyłanie pliku na serwer za pomocą fetch
            const response = await axios.post("api/noty/upload", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
  
            if (response.status === 200) {
              setWysylkaStan( 
                prevState => ({
                  ...prevState,   
                  [`stan_${obiekt?.nota}`]: "Plik zapisany na serwerze."        
              }))
              ref.style.display = "none";
              addToList(obiekt?.mieszkaniec?.id_user, obiekt?.mieszkaniec?.id_wspolnoty, obiekt?. nota, fileName, msc, obiekt?.grupyNoty?.grupa_numer)
              
            } else {
              console.error("Błąd podczas wysyłania pliku na serwer.");
            }
          } catch (error) {
            console.error("Wystąpił błąd podczas wysyłania pliku: ", error);
          }
        },
        x: 0,
        y: 0,
        width: 210,
        windowWidth: ref.scrollWidth,
        html2canvas: {
          scale: 0.25, // Zmniejsz skalę, aby zmniejszyć rozmiar obrazu (jeśli w ogóle jest używany)
        },
        autoPaging: "text", // jsPDF automatycznie dodaje nowe strony, jeśli treść jest za długa
      });
    }


  };

  const generateDocks = () => {
    return new Promise((resolve, reject) =>{
      const wygenerowanyKodHTML = tablicaObiektow?.map((obj, index) => {
        setWysylkaStan( 
          prevState => ({
            ...prevState,   // Zachowanie poprzedniego stanu
            [`stan_${obj?.nota}`]: "Przygotouję dane..."        // Dodanie nowego klucza 'wiek' z wartością 20
          })
        )
        return (
          <div           
            id={`header_${obj.nota}`}
            ref={(el) => 
              (divRefs.current[index] = {ref: el, refNr: obj.nota})
            }
            key={index}
            // style={ activeDock == obj.nota || showAll ? {display: "block"} : {display: "none"}}
            // style={{display: "none"}}
            className={`previewDock n_${obj.nota}`}

          >
            <NotaA4 
                componentRef={null}   
                wspolnota={wspolnota} 
                mieszkaniec={obj.mieszkaniec}
                tablicaCzynsze={tablicaCzynsze}
                udzialGrupaNota={obj.udzialyKontaAkt_u}
                lokale={lokale}
                datyNoty={obliczDatyNoty()}
                setWysylkaStan={setWysylkaStan}
                nota={obj.nota}
                notaIndex={index}
                setLoaderOn={setLoaderOn}
                liczbaNot={tablicaObiektow?.length}
            />
          </div>
        )
        
      });
      setDockList(wygenerowanyKodHTML);
      resolve();
    })
  }

  const addToList = async (id_user, id_wspolnoty, id_noty, nazwa_pliku, msc, grupa_numer) => {
    try {
      // Wysłanie PDF na backend
      const response = await axios.post('/api/noty/dodaj', {id_user, id_wspolnoty, id_noty, nazwa_pliku, msc, grupa_numer});

      if (response.data.success) {
        dispatch(fetchNoty());

      } else {
        alert('Wystąpił problem z wysyłaniem danych.');
      }
    } catch (error) {
      console.error('Błąd podczas wysyłania danych.', error);
    }
  }
    
  const handleHide = () => {
    setActiveDock(0);
    setShowAll(false);
  }

  const onClick = () => {
    setLoaderOn(true);
    setShowAll(true);
    generateDocks()
      .then(()=>{
        setLoaderOn(false);
      })
      .catch( error => {
        console.log('Error: ', error)
      });
  }
  
  return (
    <div>
      {/* Dynamicznie generowane komponenty na podstawie tablicy obiektów */}
      <button
        onClick={onClick}
        disabled={activeButton}
      >
        1. Wygeneruj podgląd
      </button>
      <button 
        onClick={generatePDF}
        disabled={activeButton}
      >
        2. Generuj pdf-y i zapisz na serwerze
      </button>
      <div 
        id="docks"
        
      />
        {docksList}

    </div>
  );
};

export default NotyMasowa;