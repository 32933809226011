import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MieszkancyListaLista from './MieszkancyListaLista';
import MieszkancyUliceBox from './MieszkancyUliceBox';

import { motion } from 'framer-motion';
import { wstepy } from '../../functions/motions';
import DialogOk from '../DialogOk';

const MieszkancyLista = (props) =>{

  const { wspolnota, mieszkancy_w, mieszkaniec, setMieszkaniec, setEdit, udzialyKontaAkt_w, grupyAkt_w, setEditDataOd} = props;

  const ulice = useSelector( state => state?.ulice?.data);
  const lokale = useSelector( state => state?.lokale?.data );
  const [ulice_w, setUlice_w] = useState([]);
  const [ulica, setUlica] = useState({id: 0, wszystkie: true});
  const [lokale_w, setLokale_w] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  useEffect( () => {
    setUlice_w( ulice?.filter( u => u?.id_wspolnoty == wspolnota?.id) )
  },[ulice, wspolnota]);

  useEffect( () => {
    setLokale_w( lokale?.filter( l => l?.id_wspolnoty == wspolnota?.id))
  },[lokale, wspolnota]);

  const [mieszkancy, setMieszkancy] = useState([]);
  useEffect( () => {
    let grupyTemp = [];
    let bezdomni = [];
    const mieszkancyTemp = [];
    if (ulica?.id > 0) {
      grupyAkt_w?.forEach( g => {
        const lokal = lokale_w?.find( l => l.id == g?.id_lokal );
        if ( lokal?.rodzaj === "mieszkanie" && lokal?.ulica == ulica?.id )
          grupyTemp.push(g)
      })
    } else if (ulica?.wszystkie) {
      setMieszkancy(mieszkancy_w)
    } else if (ulica?.bezGrupy) {
      bezdomni = mieszkancy_w.filter(mieszkaniec => 
        !grupyAkt_w.some(grupa => grupa.id_user === mieszkaniec.id_user)
      );
    }
    if (!bezdomni?.length && ulica?.id > 0) {
      grupyTemp?.forEach( t => {
        const mieszkaniec = mieszkancy_w?.find( m => m.id_user == t?.id_user );
        if (mieszkaniec && !mieszkancyTemp?.some( m => m.id_user == mieszkaniec?.id_user))
          mieszkancyTemp.push(mieszkaniec)
      })

      setMieszkancy(mieszkancyTemp);
    } else if (bezdomni?.length) {
      setMieszkancy(bezdomni)
    }
  
  }, [ulica, grupyAkt_w, mieszkancy_w])
  
  return (
      <motion.div 
        className="content listaMieszkancow"
        variants={wstepy}
        initial="initial"
        animate="animate"
      >
          <DialogOk
            open={open}
            setOpen={setOpen}
            message={message}
          />

          <MieszkancyUliceBox
            ulice_w={ulice_w}
            ulica={ulica}
            setUlica={setUlica}
            setMieszkaniec={setMieszkaniec}
          />
          <MieszkancyListaLista
            mieszkancy={mieszkancy}
            mieszkaniec={mieszkaniec}
            setMieszkaniec={setMieszkaniec}
            setEdit={setEdit}
            udzialyKontaAkt_w={udzialyKontaAkt_w}
            setEditDataOd={setEditDataOd}
          />
      </motion.div>
  )
}

export default MieszkancyLista;

