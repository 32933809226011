import React from "react";
import RozliczenieWydrukA4THead from "./RozliczenieWydrukA4THead";
import RozliczenieWydrukA4TBody from "./RozliczenieWydrukA4TBody";
import RozliczenieWydrukA4TFoot from "./RozliczenieWydrukA4TFoot";

const RozliczenieWydrukA4 = props => {

    const { componentRef, wspolnota, miesiac, stawki_w, stawkiJednorazowe_w, czynszeMsc,
        idw, lokale, ulice, grupy, udzialyKontaAkt, przelewyWspolnota, czynszeWspolnota, mieszkancy
    } = props;

    return (
        <div    
            style={{
                width: '29.7cm',
                minHeight: '21cm',
                backgroundColor: 'white',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                padding: '5mm 5mm',
                boxSizing: 'border-box',
                maxWidth: '100%',
                maxHeight: '100%',
                fontSize: '12px'
            }} 
            ref={componentRef ? componentRef : null}
        >
            <div style={{marginBottom: "10px"}}>
                Rozliczenie wspólnoty {wspolnota?.name} w miesiącu {miesiac}
            </div>
            <table
                style={{
                    fontSize: '10px',
                    borderCollapse: 'collapse',
                    // width: '100%'
                }}
            >
                <RozliczenieWydrukA4THead
                    stawki_w={stawki_w}
                    stawkiJednorazowe_w={stawkiJednorazowe_w}
                    czynszeMsc={czynszeMsc}
                />
                <RozliczenieWydrukA4TBody
                    idw={idw}
                    lokale={lokale}
                    ulice={ulice}
                    grupy={grupy}
                    udzialyKontaAkt={udzialyKontaAkt}
                    przelewyWspolnota={przelewyWspolnota}
                    czynszeWspolnota={czynszeWspolnota}
                    mieszkancy={mieszkancy}
                    stawki_w={stawki_w}
                    stawkiJednorazowe_w={stawkiJednorazowe_w}
                    czynszeMsc={czynszeMsc}
                />
                <RozliczenieWydrukA4TFoot
                    udzialyKontaAkt={udzialyKontaAkt}
                    stawki_w={stawki_w}
                    stawkiJednorazowe_w={stawkiJednorazowe_w}
                    czynszeMsc={czynszeMsc}
                />
                

            </table>

            
        </div>
    )

}

export default RozliczenieWydrukA4;