import React, {useEffect, useState } from 'react';
import { zl } from '../../../functions/utilities.js';
import { arrayHasElement, usunKluczNaN, filterKeysWithNumbers } from '../../../functions/tablice.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'

const MieszkancyRozliczenieList = (props) => {

    const { grupa_numer, czynszeMieszkaniec, listaMsc, wybierzMiesiac } = props;

    const [czynszeGrupa, setCzynszeGrupa] = useState([]);
    const [czynszeMieszkaniecBezNaN, setCzynszeMieszkaniecBezNan] = useState([]);
    const [headers, setHeaders] = useState([]);

    useEffect( () => setCzynszeMieszkaniecBezNan( usunKluczNaN(czynszeGrupa) ), [czynszeGrupa]);

    useEffect( () => {
        if (czynszeGrupa && czynszeGrupa?.length > 0) {

            const temp = filterKeysWithNumbers(czynszeGrupa);

            const zredukowanaTablica = temp.reduce((acc, obj) => {
                Object.keys(obj).forEach(key => {
                    if (!acc.includes(key)) {
                        acc.push(key);
                    }
                });
                return acc;
            }, []);
            setHeaders(zredukowanaTablica)
        }

    },[czynszeGrupa])

    useEffect( () => setCzynszeGrupa( czynszeMieszkaniec?.find( c => c?.grupa_numer == grupa_numer)?.temp_msc ), [czynszeGrupa]);
    
    const tab = listaMsc?.map( (msc,i) => {
        const czynszMiesiac = czynszeMieszkaniecBezNaN?.find( czynsz => czynsz.msc == msc);

        return (   
            <tr key={i}>
                <td className="pointer" style={{"fontWeight": 800}} >{ msc }</td>
                { arrayHasElement(headers, "eksploatacja") ? (<td>{ zl(czynszMiesiac?.eksploatacja) }</td> ) : ""}
                { arrayHasElement(headers, "garaz") ? ( <td>{ zl(czynszMiesiac?.garaz) }</td> ) : ""}
                { arrayHasElement(headers, "strych") ? (<td>{ zl(czynszMiesiac?.strych) }</td> ) : ""}
                { arrayHasElement(headers, "komorka") ? (<td>{ zl(czynszMiesiac?.komorka) }</td> ) : ""}
                { arrayHasElement(headers, "utrzymanie_czystosci") ? (<td>{ zl(czynszMiesiac?.utrzymanie_czystosci) }</td> ) : ""}
                { arrayHasElement(headers, "wywoz_smieci") ?  (<td>{ zl(czynszMiesiac?.wywoz_smieci) }</td> ) : "" }
                { arrayHasElement(headers, "fundusz_remontowy") ? (<td>{ zl(czynszMiesiac?.fundusz_remontowy) }</td> ) : ""}
                { arrayHasElement(headers, "konserw_domofonu") ? (<td>{ zl(czynszMiesiac?.konserw_domofonu) }</td> ) : ""}
                { arrayHasElement(headers, "konserw_windy") ? (<td>{ zl(czynszMiesiac?.konserw_windy) }</td> ) : ""}
                { arrayHasElement(headers, "opl_za_adm") ? (<td>{ zl(czynszMiesiac?.opl_za_adm) }</td> ) : ""}
                { arrayHasElement(headers, "abonament_cw") ? ( <td>{ zl(czynszMiesiac?.abonament_cw)}</td> ) : ""}
                { arrayHasElement(headers, "co") ? (<td>{ zl(czynszMiesiac?.co) }</td> ) : ""}
                { arrayHasElement(headers, "energia_el_lokalu") ? (<td>{ zl(czynszMiesiac?.energia_el_lokalu) }</td> ) : ""}
                { arrayHasElement(headers, "ryczalt_gaz") ? (<td>{ zl(czynszMiesiac?.ryczalt_gaz) }</td> ) : ""}
                { arrayHasElement(headers, "zagosp_terenu") ? (<td>{ zl(czynszMiesiac?.zagosp_terenu) }</td> ) : ""}
                { arrayHasElement(headers, "antena") ? (<td>{ zl(czynszMiesiac?.antena) }</td> ) : ""}
                { arrayHasElement(headers, "ciepla_woda") ? (<td>{ zl(czynszMiesiac?.ciepla_woda) }</td> ) : ""}
                { arrayHasElement(headers, "zimna_woda") ? (<td>{ zl(czynszMiesiac?.zimna_woda) }</td> ) : ""}
                { arrayHasElement(headers, "dod_1") ? (<td>{ zl(czynszMiesiac?.dod_1) }</td> ) : ""}
                { arrayHasElement(headers, "dod_2") ? (<td>{ zl(czynszMiesiac?.dod_2) }</td> ) : ""}
                { arrayHasElement(headers, "dod_3") ? (<td>{ zl(czynszMiesiac?.dod_3) }</td> ) : ""}
                { arrayHasElement(headers, "jednorazowaInd_kwota") ? (<td>{ zl(czynszMiesiac?.jednorazowaInd_kwota) }</td> ) : ""}
                { arrayHasElement(headers, "czynsz_na_msc") ? (<td>{ zl(czynszMiesiac?.czynsz_na_msc) }</td> ): "" }
                {   
                    <td
                        style={{textAlign: "center"}}
                    >
                        <FontAwesomeIcon 
                            icon={faPrint} 
                            className='pointer'
                            onClick={()=> {wybierzMiesiac(msc)}}
                        />
                    </td>
                }
            </tr>
        )
    })
    
    return (
        <tbody>
            {tab}
        </tbody>
    )
}

export default MieszkancyRozliczenieList;