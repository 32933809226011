import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import { dataFormat } from "../../functions/dataFormat";
import { zl } from "../../functions/utilities";

const MieszkancyDaneGrupyHistoriaWplat = props => {

    const { przelewyGrupyPokaz, setPrzelewyGrupyPokaz, przelewy, udzialGrupa, tablicaPrzelewy_g } = props;

    const onClickHandle = () => {
        if (przelewyGrupyPokaz.show && przelewyGrupyPokaz.id == udzialGrupa?.id) {
            setPrzelewyGrupyPokaz({id: 0, show: false})
        } else if (przelewyGrupyPokaz.show && przelewyGrupyPokaz.id != udzialGrupa?.id)
            setPrzelewyGrupyPokaz({id: udzialGrupa.id, show: true})
        else
            setPrzelewyGrupyPokaz({id: udzialGrupa?.id, show: true});
    }

    let listaWplat = [];

    if (tablicaPrzelewy_g?.przelewy?.length > 0) {
        listaWplat = tablicaPrzelewy_g?.przelewy?.map( (p,i) => {
            return (
                <li key={i}>
                    <div>
                        {dataFormat(p.data_operacji)}
                    </div>
                    <div>
                        {zl(p.kwota)}
                    </div>
                </li>
            )
        });
    }

    const wplaty = (
        <div className="zmiana">
            <ul className="historiaWplat">
                {listaWplat?.length > 0 ? listaWplat : <span className="brakWplat">brak wpłat w tej grupie</span>}
            </ul>
        </div>
    )

    return (
        <div className="historia">
            <h4>
                <span 
                    onClick={()=>{onClickHandle()}}
                >
                    Historia wpłat { przelewyGrupyPokaz.show && przelewyGrupyPokaz.id == udzialGrupa?.id ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} /> }
                </span>
            </h4>
            { przelewyGrupyPokaz.show && przelewyGrupyPokaz.id == udzialGrupa?.id && wplaty}
        </div>
    )
}

export default MieszkancyDaneGrupyHistoriaWplat;