import React, { useState, useEffect } from "react";
import { adresPro, findMieszkanieId } from '../../../functions/ulice';
import { useSelector, useDispatch } from 'react-redux';
import { dataFormat2 } from "../../../functions/dataFormat";
import WspolnotyStawkiJednorazoweGrupyForm from "./WspolnotyStawkiJednorazoweGrupyForm";
import WspolnotyStawkiJednorazoweGrupyLista from "./WspolnotyStawkiJednorazoweGrupyLista";

const WspolnotyStawkiJednorazoweGrupy = props => {

    const { wspolnota, mieszkancy, udzialyKontaAkt } = props;

    const grupy = useSelector( state => state?.grupy?.data);
    const lokale = useSelector( state => state?.lokale?.data);
    const ulice = useSelector( state => state?.ulice?.data);
    const stawkiJednorazoweInd = useSelector( state => state?.stawkiJednorazoweInd?.data);

    const [wybranyMieszkaniec, setWybranyMieszkaniec] = useState([]);
    const [wybranaGrupa, setWybranaGrupa] = useState([]);
    const [udzialyKontaMieszkaniec, setUdzialyKontaMieszkaniec] = useState([]);
    const [grupy_m, setGrupy_m] = useState([]);
    const [stawka, setStawka] = useState({msc: dataFormat2(new Date(new Date().setDate(1))), opis: "", kwota: 0}); 

    const mieszkancyLista = mieszkancy?.filter(m => m?.id_wspolnoty == wspolnota?.id)?.map( (mieszkaniec, i) => {
        return (
            <option 
                key={i} 
                value={mieszkaniec?.id_user}
            >
                {mieszkaniec?.imie_nazwisko}
            </option>
        )
    })
        
    useEffect( () => setGrupy_m( grupy?.filter( g => g?.id_user == wybranyMieszkaniec )), [wybranyMieszkaniec, grupy] );
    useEffect( () => setUdzialyKontaMieszkaniec( udzialyKontaAkt?.filter( m => m?.id_user == wybranyMieszkaniec )), [udzialyKontaAkt, wybranyMieszkaniec]);
    useEffect( () => setStawka( {msc: dataFormat2(new Date(new Date().setDate(1))), opis: "", kwota: 0} ), [wybranaGrupa, wybranyMieszkaniec]);

    const grupyLista = udzialyKontaMieszkaniec && udzialyKontaMieszkaniec?.map( (grupa, i) => {

        const mieszkanieId = findMieszkanieId(grupa, grupy_m, lokale );
        const mieszkanie = adresPro(mieszkanieId, lokale, ulice)

        return (
            <option
                key={i}
                value={grupa?.grupa_numer}
            >
                {`Grupa numer ${grupa?.grupa_numer}, ${mieszkanie}`}
            </option>
        )
    })

    const handleMieszkaniec = e => setWybranyMieszkaniec( e.target.value );   
    const handleGrupa = e => setWybranaGrupa( e.target.value );

    return (
        <div className="stawkiJednorazoweGrupy">
            <h3>
                Stawki jednorazowe indywidualne
            </h3>
            <div
                className="wybor"
            >
                <div className="mieszkancy">
                    <select
                        onChange={handleMieszkaniec}
                        value={wybranyMieszkaniec}
                    >
                        <option key={0}>
                            Wybierz mieszkańca:
                        </option>
                        {mieszkancyLista}
                    </select>
                </div>
                <div className="grupy">
                    <select
                        onChange={handleGrupa}
                        value={wybranaGrupa}
                    >
                        <option key={0}>
                            Wybierz grupę:
                        </option>
                        {grupyLista}
                    </select>
                </div>
                {wybranaGrupa?.length ? (
                    <WspolnotyStawkiJednorazoweGrupyForm 
                        stawka={stawka}
                        setStawka={setStawka}
                        wspolnota={wspolnota}
                        wybranyMieszkaniec={wybranyMieszkaniec}
                        wybranaGrupa={wybranaGrupa}
                    />
                ) : ""}
            </div>
            <WspolnotyStawkiJednorazoweGrupyLista
                wspolnota={wspolnota}
                mieszkancy={mieszkancy}
                lokale={lokale}
                ulice={ulice}
            />
        </div>
    )

}

export default WspolnotyStawkiJednorazoweGrupy;
