import React, {useEffect, useState} from 'react';
import CsvUploader from './ImportCSV2'
import ImportTransakcjiWgranaLista from './ImportTransakcjiWgranaLista'
import ImportTransakcjiSend from './ImportTranskacjiSend';
import '../../../scss/style.css';
import '../../../scss/form.css';
import '../../../scss/listy.css';
import '../../../scss/ksiegowosc.css';
import XMLFileReader from './XMLFileReader';

const ImportTransakcji = ( props ) => {

    const {wspolnoty, udzialyKontaAkt, mieszkancy, przelewy} = props;

    const [ pelneDane, setPelneDane ] = useState([]);
    const [data, setData] = useState([]);
    const [fileName, setFileName] = useState("");
    const [wczytane, setWczytane] = useState([]);
    const [czyDubel, setCzyDubel] = useState(false);
    const [wybranaWspolnota, setWybranaWspolnota] = useState(null);
    const [czyWplataNaKontoGlowne, setCzyWplataNaKontoGlowne] = useState(false);

    useEffect( () => {
        if (wczytane && wczytane?.length) {
            const temp = [];
            wczytane?.forEach( w => {
                
                const udzialKontoAkt = udzialyKontaAkt?.find( u => (u.konto_bankowe === w.nadawcaKonto && w.nadawcaKonto?.length ) || (u.konto_bankowe === w.odbiorcaKonto && w.odbiorcaKonto?.length ))
                

                temp.push({
                    id_transkacji: w?.przelewId,
                    data_operacji: w?.dataOperacji,
                    data_wczytania: w?.dataWczytania,
                    kwota: w?.kwota,
                    id_user: udzialKontoAkt?.id_user,
                    grupa_numer: udzialKontoAkt?.grupa_numer,
                    id_wspolnoty: wybranaWspolnota !== null ? wybranaWspolnota.id : udzialKontoAkt?.id_wspolnoty,
                    nadawca_nazwa: w?.nadawcaNazwa,
                    nadawca_konto: w?.nadawcaKonto,
                    nazwa_transakcji: w?.tytul,
                    nazwa_pliku: w?.plik,
                    operacja: w?.rodzajOperacji,
                    odbiorca_nazwa: w?.odbiorcaNazwa,
                    odbiorca_konto: w?.odbiorcaKonto,                    
                });
            })
            setPelneDane(temp);
            
        }
    },[wczytane, udzialyKontaAkt, fileName, wybranaWspolnota]);

    return (
        <div className="przelewy">
            <h4 className="nazwa">
                Import przelewów
            </h4>

            <div className='import'>
                <XMLFileReader
                    setWczytane={setWczytane}
                    setPelneDane={setPelneDane}
                    przelewy={przelewy}
                    setCzyDubel={setCzyDubel}
                    setWybranaWspolnota={setWybranaWspolnota}
                />
                {
                    czyDubel ? (
                        <span className='red'>Plik o tej nazwie został już kiedyś wczytany.</span>
                    ) : (
                        <>
                            <ImportTransakcjiWgranaLista 
                                fileName={fileName}
                                pelneDane={pelneDane}
                                setPelneDane={setPelneDane}
                                wspolnoty={wspolnoty}
                                mieszkancy={mieszkancy}
                                setWczytane={setWczytane}
                                wybranaWspolnota={wybranaWspolnota}
                                setWybranaWspolnota={setWybranaWspolnota}
                                czyWplataNaKontoGlowne={czyWplataNaKontoGlowne}
                                setCzyWplataNaKontoGlowne={setCzyWplataNaKontoGlowne}
                            />
                            <ImportTransakcjiSend
                                pelneDane={pelneDane}
                                setData={setData}
                                setFileName={setFileName}
                                setPelneDane={setPelneDane}
                                setCzyDubel={setCzyDubel}
                                setWczytane={setWczytane}
                                setCzyWplataNaKontoGlowne={setCzyWplataNaKontoGlowne}
                            />
                        </>
                    )
                }

            </div>
        </div>
    )
}

export default ImportTransakcji;