import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import FiltrWspolnoty from "../FiltrWspolnoty"
import FiltrMieszkancy from "../FiltrMieszkancy";
import PrzeksiegowaniaForm from "./PrzeksiegowaniaForm";
import { sortByTxt, sortByNum } from "../../../functions/utilities";


const Przeksiegowania = props => {

    const {wspolnoty, udzialyKontaAkt, mieszkancy, przelewy, tablicaCzynsze, tablicaPrzelewy} = props;
    const dispatch = useDispatch();
    
    const [wybranaWspolnotaId, setWybranaWpolnotaId] = useState(0);
    const [wybranyMieszkaniecId, setWybranyMieszkaniecId] = useState(0)
    const [przelewy_u, setPrzelewy_u] = useState([]);
    const [mieszkancy_w, setMieszkancy_w] = useState([]);
    const [udzialyKontaAkt_u, setUdzialyKontaAkt_u] = useState([]);   

    const [kontoGlowneSaldo_w, setKontoGlowneSaldo_w] = useState(0);

    useEffect( () => {
        const przelewy_w = przelewy?.filter( p => p?.id_wspolnoty == wybranaWspolnotaId && (
            p?.odbiorca_konto === "Konto główne wspólnoty" && p.operacja === "przelew przychodzący" 
                || p?.nadawca_konto === "Konto główne wspólnoty" && p.operacja === "przeksięgowanie z"
        )  );
        let saldo = 0;
        if (przelewy_w.length > 0) {
            saldo = przelewy_w?.reduce( (suma, obiekt) => suma + obiekt.kwota, 0 );
            setKontoGlowneSaldo_w(saldo);
        } else {
            setKontoGlowneSaldo_w(0)
        }
    },[przelewy, wybranaWspolnotaId]);

    useEffect( () => {
        if (wspolnoty && wspolnoty?.length > 0)  
            setWybranaWpolnotaId( wspolnoty[0]?.id);
    },[wspolnoty, mieszkancy, przelewy]);

    useEffect( () => setPrzelewy_u( przelewy?.filter( p => p.id_user ==  wybranyMieszkaniecId)), [przelewy, wybranyMieszkaniecId]);
    useEffect( () => setMieszkancy_w( sortByTxt(mieszkancy?.filter( m => m.id_wspolnoty == wybranaWspolnotaId),"imie_nazwisko")), [mieszkancy, wybranaWspolnotaId]);
    useEffect( () => setUdzialyKontaAkt_u( sortByNum(udzialyKontaAkt?.filter( u => u?.id_user == wybranyMieszkaniecId ), "grupa_numer")), [udzialyKontaAkt, wybranyMieszkaniecId]);

    return (
        <div className="przelewy">
            <h4 className="nazwa">
                Przeksięgowania
            </h4>
            <div className='historia'>
                <div className='filtry'>
                    <FiltrWspolnoty 
                        wspolnoty={wspolnoty}
                        wybranaWspolnotaId={wybranaWspolnotaId}
                        setWybranaWpolnotaId={setWybranaWpolnotaId}
                        setWybranyMieszkaniecId={setWybranyMieszkaniecId}
                    />
                    <FiltrMieszkancy 
                        mieszkancy_w={mieszkancy_w}
                        wybranyMieszkaniecId={wybranyMieszkaniecId}
                        setWybranyMieszkaniecId={setWybranyMieszkaniecId}
                    />
                </div>
            </div>
            {
                wybranyMieszkaniecId ? (
                    <PrzeksiegowaniaForm
                        przelewy_u={przelewy_u}
                        udzialyKontaAkt_u={udzialyKontaAkt_u}
                        tablicaCzynsze={tablicaCzynsze}
                        tablicaPrzelewy={tablicaPrzelewy}
                        wybranaWspolnotaId={wybranaWspolnotaId}
                        wybranyMieszkaniecId={wybranyMieszkaniecId}
                        setWybranyMieszkaniecId={setWybranyMieszkaniecId}
                        kontoGlowneSaldo_w={kontoGlowneSaldo_w}
                    />
                ) : ""
            }


        </div>
    )
}

export default Przeksiegowania;