import React, { useContext, useState, useEffect } from "react";

import { useSelector, useDispatch } from 'react-redux';
import { 
    fetchGlosowanieKarty,
    fetchGlosowaniePytania,
    fetchGlosowanieOdpowiedzi,
    fetchGlosowanieUchwaly
} from '../../../Redux/Actions.js'; 
import MieszkaniecGlosowanieKarta from "./MieszkaniecGlosowanieKarta.js";
import MieszkaniecGlosowanieListaKart from "./MieszkaniecGlosowanieListaKart.js";


const MieszkaniecGlosowanie = props => {
 
    const dispatch = useDispatch();

    const { wspolnota, mieszkaniec } = props;

    const glosowanieKarty = useSelector( state => state?.glosowanieKarty?.data);
    const glosowaniePytania = useSelector( state => state?.glosowaniePytania?.data);
    const glosowanieOdpowiedzi = useSelector( state => state?.glosowanieOdpowiedzi?.data);
    const glosowanieUchwaly = useSelector( state => state?.glosowanieUchwaly?.data);

    const [glosowanieKarty_w, setGlosowanieKarty_w] = useState(null);
    
    const [odpowiedzi, setOdpowiedzi] = useState([]); 

    const [karta, setKarta] = useState([]);
    const [kartaPytania, setKartaPytania] = useState([]);

    useEffect( () => setGlosowanieKarty_w( glosowanieKarty?.filter( g => g?.id_wspolnoty == wspolnota?.id) ), [glosowanieKarty, wspolnota]);
  
    useEffect( () => {
        if (glosowanieKarty_w?.length > 0)
        setKarta( glosowanieKarty_w[ 0 ] );
    },[glosowanieKarty_w]);

    useEffect( () => {
        setKartaPytania(glosowaniePytania?.filter( p => p?.id_karty == karta?.id));
        setOdpowiedzi([])
    },[karta, glosowaniePytania])

    useEffect( ()=> {
        dispatch(fetchGlosowanieKarty());
        dispatch(fetchGlosowaniePytania());
        dispatch(fetchGlosowanieOdpowiedzi());
        dispatch(fetchGlosowanieUchwaly());
    },[dispatch]);

    return (
        <div className="glosowanie">
            <MieszkaniecGlosowanieListaKart
                glosowanieKarty={glosowanieKarty_w}
                wybranaKarta={karta}
                setKarta={setKarta}
                setKartaPytania={setKartaPytania}
                glosowanieOdpowiedzi={glosowanieOdpowiedzi}
                mieszkaniec={mieszkaniec}
            />
            <MieszkaniecGlosowanieKarta
                glosowanieOdpowiedzi={glosowanieOdpowiedzi}
                glosowanieUchwaly={glosowanieUchwaly?.find( u => u?.id_karty == karta?.id)}
                kartaPytania={kartaPytania}
                odpowiedzi={odpowiedzi}
                setOdpowiedzi={setOdpowiedzi}
                karta={karta}
                wspolnota={wspolnota}
                mieszkaniec={mieszkaniec}
            />

            
        </div>

    )
}

export default MieszkaniecGlosowanie;