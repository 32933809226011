import React from 'react';

const NotaAdresat = (props) => {

    const { mieszkaniec } = props;

    return (
        <section
            style={{
                display: 'flex',
                justifyContent: 'right',
                fontSize: '13px',
                margin: '10mm 0 0 0'
            }}
        >
            <div>
                <span
                    style={{fontWeight: "600"}}
                >
                    Adresat:<br/>
                    {mieszkaniec?.notaFirma}<br/>
                    {`${mieszkaniec?.notaAdres}`}<br/>
                    {`${mieszkaniec?.notaKodMiasto}`}<br/>
                    {`NIP: ${mieszkaniec?.nip}`}

                </span>
            </div>
        </section>
    )
}

export default NotaAdresat;