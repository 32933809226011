import React, { useEffect, useState } from "react";
import axios from 'axios';
import { motion } from "framer-motion";
import { wstepy } from "../../functions/motions";
import { dataFormat } from "../../functions/dataFormat";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint, faEye } from '@fortawesome/free-solid-svg-icons'

const PrzelgadSzczegoly = props => {

    const { glosowaniePytania, glosowanieOdpowiedzi, glosowanieUchwaly, activeCard, tablicaMieszkancyUdzialy, handlePrint, setPytanieWydruk } = props;

    const [pytania, setPytania] = useState(null);
    
    useEffect( () => setPytania( glosowaniePytania?.filter( g => g?.id_karty == activeCard?.id )), [glosowaniePytania, activeCard]);

    const handleClick = id => {
        handlePrint();
        setPytanieWydruk(id);
    }

    const pobierz = async fileName => {
        console.log(fileName)
        try {
            const response = await axios.post( 
                '/api/glosowanie-uchwaly/pobierz',
                { fileName: fileName }, 
                { responseType: 'blob' })
            console.log(response);
            // if (response.statusText !== "OK") {
            //   throw new Error('Błąd podczas pobierania pliku PDF');
            // }
      
            const url = window.URL.createObjectURL(new Blob([response.data]));
      
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // Nazwa pliku do pobrania
            document.body.appendChild(link);
            console.log(link)
            link.click();
            link.parentNode.removeChild(link);
          } catch (error) {
            console.log(error);
            alert(`Plik "${fileName}" nie istnieje.`)
          }
    }   

    const pytaniaLista = pytania?.map( (pytanie, i) => {
        
        const zaUsers = glosowanieOdpowiedzi?.filter( g => g?.id_karty == pytanie.id_karty && g.id_pytania == pytanie.id && g.odp == 1);
        const zaProc = zaUsers.map((odpowiedz) => {
            const mieszkaniec = tablicaMieszkancyUdzialy.find(
              (m) => m.id_user === odpowiedz.id_user
            );
            return {
              id_user: odpowiedz.id_user,
              udzialProc: mieszkaniec ? mieszkaniec.udzialProc : 0, // Jeśli brak id_user, ustaw 0
            };
        });
        const zaProcSuma = zaProc.reduce((acc, obj) => acc + obj.udzialProc, 0);

        const przeciwUsers = glosowanieOdpowiedzi?.filter( g => g?.id_karty == pytanie.id_karty && g.id_pytania == pytanie.id && g.odp == 2);
        const przeciwProc = przeciwUsers.map((odpowiedz) => {
            const mieszkaniec = tablicaMieszkancyUdzialy.find(
              (m) => m.id_user === odpowiedz.id_user
            );
            return {
              id_user: odpowiedz.id_user,
              udzialProc: mieszkaniec ? mieszkaniec.udzialProc : 0, // Jeśli brak id_user, ustaw 0
            };
        });
        const przeciwProcSuma = przeciwProc.reduce((acc, obj) => acc + obj.udzialProc, 0);

        return (
            <tr
                key={i}
            >
                <td className="tresc">
                    {`${i+1}. ${pytanie.tresc}`}
                </td>
                <td className="central">
                    {zaProcSuma}
                </td>
                <td className="central">
                    {przeciwProcSuma}
                </td>
                <td className="center">
                    <FontAwesomeIcon 
                        icon={faPrint} 
                        className='pointer'
                        onClick={()=>{handleClick(pytanie.id)}}
                    />
                </td>
            </tr>  
        )
    });

    let liczbaOddanychGlosow = 0;
    liczbaOddanychGlosow += tablicaMieszkancyUdzialy?.reduce((acc, obj) => acc + obj.czyOdp,0); 

    const karta = (
        <div className="szczegoly ramka ramka-border-shadow ramka-wnetrze-ramki">
            <p 
                className="numer pointer"
                onClick={()=>pobierz(glosowanieUchwaly?.nazwa_pliku)}
            >
                {activeCard?.numer}
                <FontAwesomeIcon 
                    icon={faEye} 
                    size="xs"
                    style={{margin: "0 10px"}}
                />
            </p>
            <span className="termin">{`Termin głosowania: ${dataFormat(activeCard?.data_od)} - ${dataFormat(activeCard?.data_do)}`}</span>
            <section className="glosy">
                <p>
                    {`Liczba oddanych głosów: ${liczbaOddanychGlosow} z ${tablicaMieszkancyUdzialy?.length}`} 
                </p>
            </section>
            <section>
                <table>
                    <thead>
                        <tr>
                            <th>Treść:</th>
                            <th>Za:</th>
                            <th>Przeciw:</th>
                            <th>Wydruk</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pytaniaLista}
                    </tbody>
                </table>            
            </section>
        </div>
    )

    const pustaKarta = (
        <div className="szczegoly ramka ramka-border-shadow ramka-wnetrze-ramki">
            <p className="numer">Wybierz kartę, by wyświetlić szczegóły.</p>
        </div>
    )

    return (
        <div
            className="przegladListaSzczegoly ramka-br-light"
        >
            {activeCard ? karta : pustaKarta}
        </div>
    )
}

export default PrzelgadSzczegoly;