import React from "react";
import { Routes, Route } from 'react-router-dom';
import WspolnotyLista from './Wspolnoty/Lista/WspolnotyLista';

import Glowna from './Strony/Glowna.js';
import Login from './Login.js';
import Zalogowany from './Zalogowany';

import FakturyLista from './Ksiegowosc/Faktury/FakturyLista';
import FakturyAddForm from "./Ksiegowosc/Faktury/FakturyAddForm";
import Ksiegowosc from "./Ksiegowosc/Ksiegowosc";
import KontaLista from './Ksiegowosc/Konta/KontaLista';
import KontaAddForm from './Ksiegowosc/Konta/KontaAddForm.js';
import KontrahentAddForm from './Ksiegowosc/Kontrahent/KontrahentAddForm';
import KontrahenciLista from './Ksiegowosc/Kontrahent/KontrahenciLista';

import Mieszkancy from "./Mieszkancy/Mieszkancy";
import MieszkancyAddForm from './Mieszkancy/MieszkancyAddForm';
import MieszkancyRozliczenieList from './Mieszkancy/Rozliczenie/MieszkancyRozliczenieList';
import Grupy from "./Wspolnoty/Grupy/Grupy.js";

import Mieszkaniec from './Mieszkaniec/Mieszkaniec';
import Zarzad from './Mieszkaniec/Zarzad.js';

import Serwis from './Mieszkaniec/Serwis.js';

import Noty from './NotyWydruk/Noty.js';

import WspolnotaRozliczenie from './Wspolnoty/Rozliczenie/WspolnotaRozliczenie';
import WspolnotaRaportZbiorowy from "./Wspolnoty/RaportZbiorowy/WspolnotaRaportZbiorowy.js";

import Woda from "./Woda/Woda";

import WspolnotyAddForm from "./Wspolnoty/WspolnotyAddForm";
import WspolnotyDane from "./Wspolnoty/WspolnotyDane";
import WspolnotyStawki from "./Wspolnoty/Stawki/WspolnotyStawki";
import WspolnotyUdzialy from './Wspolnoty/Udzialy/WspolnotyUdzialy.js';

import Komunikaty from './Komunikaty/Komunikaty';

import ONas from './Strony/ONas';
import Kontakt from './Strony/Kontakt';

import ImportTransakcji from "./Ksiegowosc/Bank/ImportTransakcji.js";
import Przeksiegowania from "./Ksiegowosc/Przeksiegowania/Przeksiegowania.js";

import PolitykaPrywatnosci from "./Strony/PolitykaPrywatnosci"

import Test from "./Test.js";

import Logi from "./Logi.js"
import NotaModal from "./NotyWydruk/NotaModal.js";

import Usterki from "./Usterki/Usterki.js";
import Glosowanie from "./Glosowanie/Glosowanie.js";

const Page = () => {
    return (
        <Routes>
            <Route path="/" exact="true" element={<Glowna />} />  
            <Route path="/login" exact="true" element={<Login />} />  
            <Route path="/zalogowany" element={<Zalogowany/>} /> 

            <Route path="/logi" element={<Logi/>} />
            <Route path="/usterki" element={<Usterki />} />
            <Route path="/glosowanie" element={<Glosowanie />} />

            <Route path="/wspolnoty" element={<WspolnotyLista />} />
            <Route path="/wspolnoty/dodaj" element={<WspolnotyAddForm />} />
            <Route path="/wspolnoty/dane" element={<WspolnotyDane />} />
            <Route path="/wspolnoty/stawki"  element={<WspolnotyStawki />} />
            <Route path="/wspolnoty/rozliczenia" element={<WspolnotaRozliczenie/>} />
            <Route path="/wspolnoty/raportZbiorowy" element={<WspolnotaRaportZbiorowy/>} />
            <Route path="/wspolnoty/udzialy" element={<WspolnotyUdzialy/>} />
            
            <Route path="/faktury" element={<FakturyLista/>} />
            <Route path="/faktury/dodaj" element={<FakturyAddForm/>} />
            <Route path="/konta" element={<KontaLista/>} />
            <Route path="/konta/dodaj" element={<KontaAddForm/>} />
            <Route path="/kontrahenci/dodaj" element={<KontrahentAddForm/>} />
            <Route path="/kontrahenci/lista" element={<KontrahenciLista/>} />
            <Route path="/ksiegowosc" element={<Ksiegowosc/>} />
            <Route path="/ksiegowosc/przeksiegowania" element={<Przeksiegowania/>}/>
            
            <Route path="/mieszkancy" element={<Mieszkancy/>} />
            <Route path="/mieszkancy/dodaj" element={<MieszkancyAddForm/>} />
            <Route path="/mieszkancy/rozliczenia" element={<MieszkancyRozliczenieList/>} />
            <Route path="/mieszkancy/nota" element={<NotaModal/>} />
            <Route path="raport-zbiorczy" element={<WspolnotaRaportZbiorowy/>} />

            <Route path="/wspolnoty/grupy" element={<Grupy/>} />

            <Route path="/noty" element={<Noty/>} />

            <Route path="/serwis" element={<Serwis/>}/>
            
            <Route path="/mieszkaniec/" element={<Mieszkaniec/>} />
            <Route path="/podsumowanie" element={<Zarzad/>} />
            
            <Route path="/woda" element={<Woda/>} />                         

            <Route path="/komunikaty" element={<Komunikaty/>} />

            <Route path="/o-nas" element={<ONas/>} />
            <Route path="/kontakt" element={<Kontakt/>} />

            <Route path="/ksiegowosc/przelewy" element={<ImportTransakcji/>}/>

            <Route path="/polityka-prywatnosci" element={<PolitykaPrywatnosci/>}/>

            <Route path="/test" element={<Test/>}/>
        </Routes>
    )
}

export default Page;