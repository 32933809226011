import React from "react";
import { useReactToPrint } from 'react-to-print';

const WynikiGlosowaniaA4Buttons = (props) => {

    const { showPrint, componentRef } = props;

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Visitor Pass',
        onAfterPrint: () => console.log('Printed PDF successfully!'),
    });
        
    return (
        <div 
            className="buttons"
            style={{
                display: "flex",
                justifyContent: "center"
            }}
        >
            <button 
                onClick={handlePrint}
            >
                Drukuj
            </button>
            <button
                onClick={showPrint}
            >
                Anuluj
            </button>
        </div>
    )
}

export default WynikiGlosowaniaA4Buttons;