const initialState = {
    data: [],
  };
  
  const stawkiJednorazoweIndReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_STAWKI_JEDNORAZOWE_IND':
        return { ...state, data: action.payload };
      default:
        return state;
    }
  };
  
  export default stawkiJednorazoweIndReducer;