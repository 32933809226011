export const usterki = [
    {
        nazwa: "(wybierz)",
        value: "0",
        subs: [{
            nazwa: " ",
            value: "0"
        }]
    },
    {
        nazwa: "Elektryka",
        value: "1",
        subs: [
            {
                nazwa: "(wybierz)",
                value: "0"
            },
            {
                nazwa: "brak światła",
                value: "1"
            },
            {
                nazwa: "brak zasilania",
                value: "2"
            },
            {
                nazwa: "przepalona żarówka",
                value: "3"
            },
            {
                nazwa: "inne",
                value: "4"
            }
        ]
    },
    {
        nazwa: "Budowlanka",
        value: "2",
        subs: [
            {
                nazwa: "(wybierz)",
                value: "0"
            },
            {
                nazwa: "płytki",
                value: "1"
            },
            {
                nazwa: "chodnik",
                value: "2"
            },
            {
                nazwa: "inne",
                value: "3"
            }
        ]
    },
    {
        nazwa: "Domofon",
        value: "3",
        subs: [
            {
                nazwa: "(wybierz)",
                value: "0"
            },
            {
                nazwa: "elektrozaczep",
                value: "1"
            },
            {
                nazwa: "unifon",
                value: "2"
            },
            {
                nazwa: "instalacja zewnętrzna (panel)",
                value: "3"
            },
            {
                nazwa: "inne",
                value: "4"
            }
        ]
    },
    {
        nazwa: "Utrzymanie czystości",
        value: "4",
        subs: [
            {
                nazwa: "(wybierz)",
                value: "0"
            },
            {
                nazwa: "klatki",
                value: "1"
            },
            {
                nazwa: "teren zewnętrzny",
                value: "2"
            },
            {
                nazwa: "zieleń",
                value: "3"
            },
            {
                nazwa: "inne",
                value: "4"
            }
        ]
    }
];