import React, { useEffect, useState } from "react";
import { isNumber, zl } from "../../../functions/utilities.js";
import { rrrr_mm_dd_to_mm_rrrr } from '../../../functions/dataFormat.js';

const MieszkaniecWydrukStawkiJednorazoweInd = props => {

    const { mieszkaniec, stawkiJednorazoweInd, licznikJednorazowe, miesiac, grupa_numer } = props;
    const [stawkiJednorazoweInd_msc, setStawkiJednorazoweInd_msc] = useState([]);

    useEffect( () => {
        setStawkiJednorazoweInd_msc( stawkiJednorazoweInd?.filter( s => rrrr_mm_dd_to_mm_rrrr(s?.msc) == miesiac && s?.id_user == mieszkaniec?.id_user && s?.grupa_numer == grupa_numer )  )
    },[stawkiJednorazoweInd])

    const puste = Array(3).fill(null).map((_, index) => (
        <td 
            style={{
                borderLeft: '1px solid black', 
                borderRight: '1px solid black',
                padding: '1mm 2mm',
                textAlign: 'center',
            }}
        >    
        </td>
    ));

    const tabela3 = s => {
        let currentMaxI = licznikJednorazowe+1; 
        return s?.map( (stawka, i) => (
            <tr key={i}>
            <td 
                style={{
                    borderLeft: '1px solid black', 
                    borderRight: '1px solid black',
                    padding: '1mm 2mm',
                    textAlign: 'center',
                }}
            >    
                { ++currentMaxI }
            </td>
            <td 
                style={{
                    borderLeft: '1px solid black', 
                    borderRight: '1px solid black',
                    padding: '1mm 2mm',
                    textAlign: 'left',
                }}
            >    
                { stawka?.opis }
            </td>
            { puste }
            <td 
                style={{
                    borderLeft: '1px solid black', 
                    borderRight: '1px solid black',
                    padding: '1mm 2mm',
                    textAlign: 'right',
                }}
            >    
                { zl(stawka.kwota) }
            </td>
        </tr>
        ))
    }

    return (
        <>
            { tabela3(stawkiJednorazoweInd_msc) }
        </>
    )
}

export default MieszkaniecWydrukStawkiJednorazoweInd;