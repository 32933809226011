import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import MieszkaniecDaneGrupy from "./MieszkaniecDaneGrupy.js";
import MieszkaniecInformacje from "./MieszkaniecInformacje.js";
import MieszkaniecRozliczenie from "./Rozliczenie/MieszkaniecRozliczenie.js";


import { 
    fetchUdzialyKontaAkt,
  } from '../../Redux/Actions.js'; 

import { motion } from 'framer-motion';
import { wstepy } from '../../functions/motions.js';


const MieszkaniecMojeDane = (props) => {

    const {activeGroup, setActiveGroup,mieszkaniec, thisUser, ulice, lokale, udzialyKonta_u, grupy_u, tablicaCzynsze_g, tablicaPrzelewy_u, stawki_w, stawkiJednorazowe_w, grupy_a, udzialyKonta_a } = props;

    const dispatch = useDispatch();


    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);


    const [saldo, setSaldo] = useState([]);

    
 
    useEffect( ( )=> {
        if (udzialyKonta_u?.length > 0) {
            setActiveGroup( { idw: udzialyKonta_u[0].id, grupa_numer: udzialyKontaAkt[0].grupa_numer } );
        }
    },[udzialyKonta_u]);


    useEffect( ()=> {
      dispatch(fetchUdzialyKontaAkt());
    },[dispatch]);


    return (
            <div className="content">

                <div className="mieszkaniecDaneContainer">
                    <motion.div 
                        className="lista ramka-br ramka-border-shadow"
                        variants={wstepy}
                        initial="initial"
                        animate="animate"
                    >
                        <h3>
                            Informacje podstawowe
                        </h3>
                        <MieszkaniecInformacje
                            mieszkaniec={mieszkaniec}
                        />
                    </motion.div>
                    <div className='grupy'>
                        <MieszkaniecDaneGrupy 
                            mieszkaniec={mieszkaniec}
                            id_user={thisUser?.id}
                            activeGroup={activeGroup}
                            setActiveGroup={setActiveGroup}
                            ulice={ulice}
                            lokale={lokale}
                            udzialyKonta_u={udzialyKonta_u}
                            grupy_u={grupy_u}
                        />
                    </div>
                </div>

                <MieszkaniecRozliczenie
                    activeGroup={activeGroup}
                    tablicaCzynsze={tablicaCzynsze_g}
                    tablicaPrzelewy={tablicaPrzelewy_u}
                    udzialyKonta_a={udzialyKonta_a}
                    stawki_w={stawki_w}
                    stawkiJednorazowe_w={stawkiJednorazowe_w}
                    mieszkaniec={mieszkaniec}
                    ulice={ulice}
                    lokale={lokale}
                    grupy_a={grupy_a}
                />
            </div>
    )
}

export default MieszkaniecMojeDane;