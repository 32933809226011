import React, { useEffect, useState } from "react";



const ListaWspolnot = props => {

    const { wspolnoty, wspolnota, setWspolnota, setActiveWindow, setActiveCard } = props;

    const wspolnotyLista = wspolnoty?.map( (wspolnota, i) => (
        <option
            key={i}
            value={wspolnota?.id}
        >
            {wspolnota?.name}
        </option>
    ))

    const handleWspolnota = e => {
        setWspolnota(wspolnoty?.find( w => w?.id == e.target.value));
        setActiveWindow("lista");
        setActiveCard(null);
    }

    return (
        <div className="wspolnoty-lista">
            <label htmlFor="wspolnota">
                Wybierz współnotę:
            </label>
            <select
                id="wspolnota"
                value={wspolnota?.id}
                onChange={handleWspolnota}
            >
                <option
                    key={0}
                >
                    (wybierz)
                </option>
                {wspolnotyLista}
            </select>
        </div>
    )
}

export default ListaWspolnot;