import React, { useState } from "react";
import { dataFormat } from "../../functions/dataFormat";


const PrzelgadLista = props => {

    const { glosowanieKarty, activeCard, setActiveCard } = props;

    const handleKarta = e => setActiveCard( glosowanieKarty?.find( g => g?.id == e.currentTarget.id) );

    const lista = glosowanieKarty?.map( (karta, i) => {


        return (
            <div
                className="karta ramka ramka-border-shadow ramka-wnetrze-ramki pointer"
                style={ karta.id == activeCard?.id ? {backgroundColor: "var(--podswietlenie)"} : {}}
                id={karta.id}
                onClick={handleKarta}
                key={i}
            >
                <p className="numer">{karta.numer}</p>
            </div>
        )
    })

    const brakKart = (
        <div 
            className="ramka ramka-border-shadow ramka-wnetrze-ramki"
        >
            Brak kart do głosowania w tej Wspólnocie
        </div>
    )

    return (
        <div
            className="przegladListaSzczegoly ramka-br-light"
        >
            { lista?.length > 0 ? lista : brakKart }
        </div>
    )
}

export default PrzelgadLista;