import React, { useState } from 'react';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import { wstepy } from '../../../functions/motions';
import { zl } from '../../../functions/utilities';

const MieszkaniecWplaty = props => {

    const { tablicaPrzelewy } = props;

    const [show, setShow] = useState(false);
    const onClickHandle = () => setShow( prevState => !prevState);

    const wplaty = tablicaPrzelewy?.map( (t,i) => (
        <tr key={i}>
            <td>
                {format(t.data_operacji, 'yyyy-MM-dd')}
            </td>
            <td>
                {t.nazwa_transakcji}
            </td>
            <td>
                {zl(t.kwota)}
            </td>
        </tr>
    ));
    
    return (
        <div >
            <h4
                onClick={onClickHandle}
                className='pointer'
            >
                Historia wpłat { show ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} /> }
            </h4>  
            { show ? (
                <motion.table
                    variants={wstepy}
                    initial="initial"
                    animate="animate"
                >
                    <thead>
                        <tr>
                            <th>
                                Data
                            </th>
                            <th style={{"wordBreak": "break-all"}}>
                                Tytuł przelewu
                            </th>
                            <th>
                                Kwota
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {wplaty}
                    </tbody>
                </motion.table>
            ) : ""}
        </div>
    )
}

export default MieszkaniecWplaty;