import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from "axios";
import '../../scss/style.css';
import '../../scss/form.css';
import passwordGenerator from '../../functions/passwordGenerator';
import { fetchMieszkancy, fetchUsers } from '../../Redux/Actions';
import { dataFormat2 } from '../../functions/dataFormat';

const MieszkancyAddForm = ( props ) =>{

    const {setOpen, setAdd, idw} = props;
    const dispatch = useDispatch();
    const handleAnuluj = () => setAdd(false);

    const [values, setValues] = useState({
        imieNazwisko: "",
        telefon: "",
        email: "",
        pwd: passwordGenerator(),
        czyZarzad: false,
        czyNota: false,
        nip: "",
        dataOd: dataFormat2(new Date()),
        notaFirma: "",
        notaAdres: "",
        notaKodMiasto: ""
    })
  
    const addUser = async () => {

        try {
            await axios.post('/api/user/dodaj', {
                id_wspolnoty: idw,
                role: "mieszkaniec",
                password: values.pwd,
                status: "active",
                user: values.telefon
    
            }).then( response => {
                addMieszkaniec(response.data.id, values);
            });
    
        } catch (error) {
            // setMessage(error);
            // setOpen(true);
            console.log(error)
        }
    }

    const addMieszkaniec = async (userId, values) => {
        try {
            axios.post('/api/mieszkaniec/dodaj', {
                id_wspolnoty: idw,
                id_user: userId,
                imie_nazwisko: values.imieNazwisko,
                telefon: values.telefon,
                email: values.email,
                data_od: values.dataOd,
                czy_zarzad: values.czyZarzad,
                status: "",
                czy_nota: values.czyNota,
                nip: values.nip,
                notaFirma: values.notaFirma,
                notaAdres: values.notaAdres,
                notaKodMiasto: values.notaKodMiasto
            }).then( response => {
                console.log(response)
                // setMessage('Dodano mieszkanca');
                // setOpen(true);
                dispatch(fetchMieszkancy()); 
                dispatch(fetchUsers())
                setAdd(false);
            })
        } catch (error) {
            // setMessage(error);
            setOpen(true);
            console.log(error)
        }
    }

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setValues(prevValues => ({
          ...prevValues,
          [name]: type === 'checkbox' ? checked : value
        }));
    };
    

    return (
        <div className="content">
            <h3 
                className="nazwa"
            >
                Dodaj mieszkańca
            </h3>
            <div className="addForm">
                <div className='wiersz'>
                    <span>
                        Imię i nazwisko:
                    </span>
                    <input 
                        type="text"
                        name="imieNazwisko"
                        value={values.imieNazwisko}
                        onChange={handleChange}
                    />
                </div>
                <div className='wiersz'>
                    <span>
                        Telefon (login):
                    </span>
                    <input
                        type="text"
                        name="telefon"
                        value={values.telefon}
                        onChange={handleChange}
                    />
                </div>
                <div className='wiersz'>
                    <span>
                        E-mail:
                    </span>
                    <input
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                    />
                </div>
                <div className='wiersz'>
                    <span>
                        Hasło:
                    </span>
                    <input
                        type="text"
                        name="pwd"
                        value={values.pwd}
                        onChange={handleChange}
                    />
                </div>
                <div className='wiersz'>
                    <span>
                        Czy zarząd?
                    </span>
                    <input
                        type="checkbox"
                        name="czyZarzad"
                        checked={values.czyZarzad}
                        onChange={handleChange}
                    />
                </div>
                <div className='wiersz'>
                    <span>
                        Czy nota?
                    </span>
                    <input
                        type="checkbox"
                        name="czyNota"
                        checked={values.czyNota}
                        onChange={handleChange}
                    />
                </div>
                {
                    values.czyNota == 1 ? (
                        <>
                            <div className='wiersz'>
                                <span>
                                    Nota - nazwa firmy:
                                </span>
                                <input
                                    type="text"
                                    name="notaFirma"
                                    value={values.notaFirma}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='wiersz'>
                                <span>
                                    Nota - ulica, nr budynku/lokalu:
                                </span>
                                <input
                                    type="text"
                                    name="notaAdres"
                                    value={values.notaAdres}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='wiersz'>
                                <span>
                                    Nota - Kod pocztowy i miejscowość:
                                </span>
                                <input
                                    type="text"
                                    name="notaKodMiasto"
                                    value={values.notaKodMiasto}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='wiersz'>
                                <span>
                                    NIP:
                                </span>
                                <input
                                    type="text"
                                    name="nip"
                                    value={values.nip}
                                    onChange={handleChange}
                                />
                            </div>
                        </>
                    ) : ""
                }

                <div className='wiersz'>
                    <span>
                        Data utworzenia:
                    </span>
                    <input
                        type="date"
                        name="dataOd"
                        value={values.dataOd}
                        onChange={handleChange}
                    />
                </div>
                <div className='buttons'>
                    <button 
                        onClick={addUser}
                    >
                        Zapisz
                    </button>
                    <button
                        onClick={handleAnuluj}
                    >
                        Anuluj
                    </button>
                </div>
            </div>       
        </div>
    )
}

export default MieszkancyAddForm;