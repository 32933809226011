import React, { useEffect, useState } from "react";

const WynikiGlosowaniaA4Podsumowanie = props => {

    const { pytanie, glosowanieOdpowiedzi, tablicaMieszkancyUdzialy } = props;

    const [zaProcSuma, setZaprocSuma] = useState(0);
    const [przeciwProcSuma, setPrzeciwProcSuma] = useState(0);

    useEffect( () => {
        const zaUsers = glosowanieOdpowiedzi?.filter( g => g?.id_karty == pytanie.id_karty && g.id_pytania == pytanie.id && g.odp == 1);
        const zaProc = zaUsers.map((odpowiedz) => {
            const mieszkaniec = tablicaMieszkancyUdzialy.find(
              (m) => m.id_user === odpowiedz.id_user
            );
            return {
              id_user: odpowiedz.id_user,
              udzialProc: mieszkaniec ? mieszkaniec.udzialProc : 0, // Jeśli brak id_user, ustaw 0
            };
        });
        setZaprocSuma(zaProc.reduce((acc, obj) => acc + obj.udzialProc, 0));
    }, [pytanie, glosowanieOdpowiedzi, tablicaMieszkancyUdzialy]);

    useEffect( () => {
        const przeciwUsers = glosowanieOdpowiedzi?.filter( g => g?.id_karty == pytanie.id_karty && g.id_pytania == pytanie.id && g.odp == 2);
        const przeciwProc = przeciwUsers.map((odpowiedz) => {
            const mieszkaniec = tablicaMieszkancyUdzialy.find(
            (m) => m.id_user === odpowiedz.id_user
            );
            return {
            id_user: odpowiedz.id_user,
            udzialProc: mieszkaniec ? mieszkaniec.udzialProc : 0, // Jeśli brak id_user, ustaw 0
            };
        });
        setPrzeciwProcSuma(przeciwProc.reduce((acc, obj) => acc + obj.udzialProc, 0));
    }, [pytanie, glosowanieOdpowiedzi, tablicaMieszkancyUdzialy]);


    return (
        <section
            style={{display: "flex", flexDirection: "column", margin: "20px"}}
        >
            <div style={{display: "flex", margin: "5px"}}>
                <div style={{width: "50px"}}>
                    <span>
                        Za:
                    </span>
                </div>
                <div style={{textAlign: "right", width: "150px"}}>
                    <span>
                        {zaProcSuma}
                    </span>
                </div>
            </div>
            <div style={{display: "flex", margin: "5px"}}>
                <div style={{width: "50px"}}>
                    <span>
                        Przeciw:
                    </span>
                </div>
                <div style={{textAlign: "right", width: "150px"}}>
                    <span>
                        {przeciwProcSuma}
                    </span>
                </div>
            </div>

        </section>
    )
}

export default WynikiGlosowaniaA4Podsumowanie;