import React from "react";

const WynikiGlosowaniaA4TBody = props => {

    const { tablicaMieszkancyUdzialy, glosowanieOdpowiedzi, tablica, pytanie } = props;

    console.log(glosowanieOdpowiedzi)

    const wiersze = tablicaMieszkancyUdzialy?.map( (mieszkaniec, i) => {

        const lokaleMieszkanca = tablica?.filter( t => t?.id_user == mieszkaniec?.id_user);

        const listaLokali = lokaleMieszkanca?.map( (lokal, i) => (
            <li 
                key={i}
                style={{
                    margin: "2px 0",
                    borderBottom: "1x solid"
                }}
            >
                {`${lokal?.adres} (${lokal?.udzialProc})`}
            </li>
        ));

        const odpowiedz = glosowanieOdpowiedzi?.find( g => g?.id_pytania == pytanie?.id && g?.id_user == mieszkaniec?.id_user)?.odp;
        console.log(odpowiedz) 
        let odpowiedzTresc = "";

        if (odpowiedz == 1)
            odpowiedzTresc = "ZA";
        else if (odpowiedz == 2)
            odpowiedzTresc = "PRZECIW"

        return (
            <tr 
                key={i}
                style={{height: "20px"}}
            >
                <td style={{border: "1px solid"}}>
                    {mieszkaniec?.imie_nazwisko}
                </td>
                <td
                    style={{border: "1px solid"}}
                >
                    <ul
                        style={{
                            listStyle: "none",
                            listStylePosition: "inside"
                        }}
                    >
                        { listaLokali }
                    </ul>
                </td>
                <td
                    style={{border: "1px solid"}}
                >
                    { odpowiedzTresc }
                </td>
                <td style={{border: "1px solid"}}></td>
            </tr>
        )
    })

    return (
        <tbody>
            { wiersze }
        </tbody>
    )
}

export default WynikiGlosowaniaA4TBody;