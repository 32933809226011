import React from "react";

const WynikiGlosowaniaA4THead = () => {

    return (
        <thead>
            <tr
                
            >
                <td 
                    style={{border: "1px solid", fontWeight: "600"}}
                >
                    Mieszkaniec
                </td>
                <td 
                    style={{border: "1px solid", fontWeight: "600"}}
                >
                    Lokal (udział)
                </td>
                <td
                    style={{border: "1px solid", fontWeight: "600"}}
                >
                    Głos
                </td>
                <td 
                    style={{border: "1px solid", fontWeight: "600", width: "200px"}}
                >
                    Podpis
                </td>
            </tr>
        </thead>
    )
}

export default WynikiGlosowaniaA4THead;