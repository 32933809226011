import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { zl } from '../../functions/utilities.js';
import { translate, rodzaj_rozliczenia } from "../../functions/dictionary.js";
import { getTablicaCzynsze2 } from '../../functions/tablice.js';

const NotaTBody = (props) => {

    const { mieszkaniec, wspolnota, lokale, udzialGrupaNota, setKwota } = props;

    const stawki = useSelector( state => state?.stawki?.data);
    const wspolnoty = useSelector( state  => state?.wspolnoty?.data);
    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const grupy = useSelector( state => state?.grupy?.data);
    const udzialyKonta = useSelector( state => state?.udzialyKonta?.data);
    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);
    const planowaneZuzycie = useSelector( state => state?.planowaneZuzycie?.data);
    const stawkiJednorazowe = useSelector( state => state?.stawkiJednorazowe?.data);
    const stawkiJednorazoweInd = useSelector( state => state?.stawkiJednorazoweInd?.data);
    const stawkiInd = useSelector( state => state?.stawkiInd?.data);

    const [tablicaCzynsze, setTablicaCzynsze] = useState([]);
    const [tablicaCzynsze_g, setTablicaCzynsze_g] = useState([]);
    const [tablicaCzynszeAkt, setTablicaCzynszeAkt] = useState([]);

    const [stawki_w, setStawki_w] = useState([]);

    useEffect( () => setStawki_w( stawki?.find( s => s?.id_wspolnoty == wspolnota?.id )), [stawki, wspolnota])

    useEffect( () => setKwota( tablicaCzynszeAkt?.czynsz_na_msc ), [tablicaCzynszeAkt]);

    useEffect( () => {
        setTablicaCzynsze(getTablicaCzynsze2(wspolnoty, mieszkancy, grupy, [],udzialyKonta, udzialyKontaAkt, lokale, stawki, planowaneZuzycie, stawkiJednorazowe, stawkiInd, stawkiJednorazoweInd /*, zaliczki */))
      },[wspolnoty, mieszkancy, grupy, udzialyKonta, lokale, stawki, planowaneZuzycie, stawkiJednorazowe, stawkiInd, stawkiJednorazoweInd /*, zaliczki */])

    useEffect( () => {
        const tablicaCzynsze_w = tablicaCzynsze?.find( t => t.id_wspolnoty == wspolnota?.id);
        const tablicaCzynsze_u = tablicaCzynsze_w?.grupy?.find( t => t.id_user == mieszkaniec?.id_user );

        setTablicaCzynsze_g( tablicaCzynsze_u?.grupy_udzial_msc?.find( g => g?.grupa_numer == udzialGrupaNota?.grupa_numer )  );
        
    },[tablicaCzynsze, mieszkaniec, wspolnota, udzialGrupaNota]);
    
    useEffect( () => {
        let len = tablicaCzynsze_g?.temp_msc?.length;
        if (len && len > 1)
            setTablicaCzynszeAkt( tablicaCzynsze_g?.temp_msc[len - 2] );
        else if (len && len === 1)
            setTablicaCzynszeAkt( tablicaCzynsze_g?.temp_msc[0] );
    },[tablicaCzynsze_g])

    const podstawa = (tablicaCzynszeAkt, key) => {
        const klucz = key + "_podstawa";
        return tablicaCzynszeAkt[klucz];
    }

    const puste = Array(6).fill(null).map((_, index) => (
        <td
            style={{
                borderLeft: '1px solid black',
                borderRight: '1px solid black',
                padding: '3cm 0 0 0'
            }}
        />
    ));

    const tabela = (stawki_w) => {
        const elements = [];
        let i = 1;
        if (stawki_w) {
            const hasOwn = Object.prototype.hasOwnProperty;
            for (const key in stawki_w) {
                if (hasOwn.call(stawki_w, key) && !key.startsWith('czy_') && !key.endsWith('_rodzaj')) {
                    const rodzajKey = `${key}_rodzaj`;
                    const czyKey = `czy_${key}`;
                    const cena = stawki_w[key];
                    const rodzaj = stawki_w[rodzajKey];
                    const czyUwzgledniany = stawki_w[czyKey] && !isNaN(tablicaCzynszeAkt[key]);
                    
                    const podstawa_ = rodzaj_rozliczenia(rodzaj, key) == "opłata stała" ? "1" :  podstawa(tablicaCzynszeAkt, key);

                    if (czyUwzgledniany && cena > 0 && tablicaCzynszeAkt[key] > 0) {
                        elements.push(
                            <tr key={key}>
                                <td 
                                    style={{
                                        borderLeft: '1px solid black', 
                                        borderRight: '1px solid black',
                                        padding: '1mm 2mm',
                                        textAlign: 'center',
                                    }}
                                >    
                                    {i++}                                     
                                </td>
                                <td 
                                    style={{
                                        borderLeft: '1px solid black', 
                                        borderRight: '1px solid black',
                                        padding: '1mm 2mm',
                                    }}
                                >
                                    {translate(key)}                        
                                </td>
                                <td 
                                    style={{
                                        borderLeft: '1px solid black', 
                                        borderRight: '1px solid black',
                                        padding: '1mm 2mm',
                                        textAlign: 'center',
                                    }}
                                >
                                    {rodzaj_rozliczenia(rodzaj, key)}       
                                </td>
                                <td 
                                    style={{
                                        borderLeft: '1px solid black', 
                                        borderRight: '1px solid black',
                                        padding: '1mm 2mm',
                                        textAlign: 'center',
                                    }}
                                >
                                    { isNaN(podstawa_) ? 0 : podstawa_}      
                                </td>
                                <td 
                                    style={{
                                        borderLeft: '1px solid black', 
                                        borderRight: '1px solid black',
                                        padding: '1mm 2mm',
                                        textAlign: 'center',
                                    }}
                                >
                                    {zl(cena)}                              
                                </td>
                                <td 
                                    style={{
                                        borderLeft: '1px solid black', 
                                        borderRight: '1px solid black',
                                        padding: '1mm 2mm',
                                        textAlign: 'right',
                                    }}
                                >    
                                    {zl(tablicaCzynszeAkt[key])}            
                                </td>
                            </tr>
                        );
                    }
                }
            }
            return elements;
        } else return ""
    };

    return (
        <tbody key={0}>
            { tabela(stawki_w) }
            <tr key={-2}>
                {puste}
            </tr>
            <tr key={-1}>
                <td
                    style={{
                        border: '1px solid black',
                        textAlign: 'right',
                        padding: '1mm 2mm',
                        fontWeight: '600'
                    }}
                    colSpan={5}
                >
                    Razem:
                </td>
                <td 
                    style={{
                        border: '1px solid black', 
                        padding: '1mm',
                        textAlign: 'right',
                        fontWeight: '600'
                    }}
                >
                    {zl(tablicaCzynszeAkt?.czynsz_na_msc)}
                </td>
            </tr>
        </tbody>
    )
}

export default NotaTBody;