import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { fetchUsterki } from "../../Redux/Actions";
import { motion } from "framer-motion";
import { wstepy } from "../../functions/motions";
import { usterki as usterkiLista } from "../../functions/usterki";
import { dataCzas, dataFormat } from "../../functions/dataFormat";

const Usterki = () => {
    
    const dispatch = useDispatch();

    const wspolnoty = useSelector( state  => state?.wspolnoty?.data);
    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const usterki = useSelector( state => state?.usterki?.data );

    const [usterki_w, setUsterki_w] = useState([]);
    const [wspolnota, setWspolnota] = useState([]);
    const [liczbaOczekujacych, setLiczbaOczekujacych] = useState(0);
    const [activeUsterka, setActiveUsterka] = useState(0);
    const [data, setData] = useState(null);
    const [comment, setComment] = useState("");

    useEffect( () => {
        dispatch(fetchUsterki());
    },[dispatch]);

    useEffect( () => {
        setUsterki_w( usterki?.filter( u => u?.id_wspolnoty == wspolnota?.id) );
    },[usterki, wspolnota]);

    useEffect( () => {
        setLiczbaOczekujacych( usterki_w?.filter( u => u.rozwiazany === null )?.length );
    },[usterki_w])

    const wspolnotyLista = wspolnoty?.map( (wspolnota, i) => (
        <option
            key={i}
            value={wspolnota?.id}
        >
            {wspolnota?.name}
        </option>
    ))

    const handleWspolnota = e => {
        setWspolnota(wspolnoty?.find( w => w?.id == e.target.value));
        setActiveUsterka(0);
    }

    useEffect( () => {
        setData(null);
        setComment("");
    },[activeUsterka]);

    const handleUsterkaClick = e => {
        const temp = usterki_w?.find( u => u.id == e.currentTarget.id);
        if (temp?.data_rozwiazania === null)
            setActiveUsterka(e.currentTarget.id);
        else
            setActiveUsterka(0);
    }

    const lista = usterki_w?.map( (usterka, i) => (
        <li
            key={i}
            style={ usterka.data_rozwiazania === null? {cursor: "pointer"} : {} }
            className={ usterka.id == activeUsterka ? "g" : "" }
            id={usterka.id}
            onClick={handleUsterkaClick}
        >
            <div>{usterka.id}</div>
            <div>{mieszkancy?.find( m => m?.id_user == usterka?.id_user)?.imie_nazwisko}</div>
            <div>{dataCzas(usterka?.data_utworzenia)}</div>
            <div>{usterkiLista?.find( u => u?.value == usterka?.cat)?.nazwa}</div>
            <div>{usterkiLista?.find( u => u?.value == usterka?.cat)?.subs?.find( s => s?.value == usterka?.subcat)?.nazwa}</div>
            <div>{usterka?.comment}</div>
            <div>{usterka?.data_rozwiazania === null ? "Oczekuje" : "Rozwiązana"}</div>
            <div>{usterka?.data_rozwiazania === null ? "-" : dataFormat(usterka?.data_rozwiazania)}</div>
            <div>{usterka?.rozwiazanie_komentarz}</div>
        </li>
    ));

    const handleData = e => setData(e.target.value);
    const handleComment = e => setComment(e.target.value);

    const handleSave = async e => {
        e.preventDefault();
        if (data == null)
            alert("Podaj datę")
        else if (comment == "")
            alert("Wpisz komentarz do rozwiązania")
        else {
            try {   
                // Wysyłamy dane na serwer
                await axios.post('/api/usterki/update', {
                    date: data,
                    comment: comment,
                    id: activeUsterka
                }).then(response => {
                    if (response.status === 200) {
                        setActiveUsterka(0);
                        dispatch(fetchUsterki());
                    }
                });
                // Po udanym dodaniu danych możesz wykonać odpowiednie akcje, np. wyczyść formularz
              } catch (error) {
                console.error('Błąd podczas dodawania danych:', error);
              }
        }
    }

    return (
        <motion.div 
            className="content ksiegowosc"
            variants={wstepy}
            initial="initial"
            animate="animate"
        >
            <h1 className="nazwa">
                Usterki
            </h1>
            <div className="usterki">
                <div className="wspolnoty-lista">
                    <label htmlFor="wspolnota">
                        Wybierz współnotę:
                    </label>
                    <select
                            id="wspolnota"
                            value={wspolnota?.id}
                            onChange={handleWspolnota}
                        >
                            <option
                                key={0}
                            >
                                (wybierz)
                            </option>
                            {wspolnotyLista}
                    </select>
                </div>
                <div className="oczekujace">
                    <p>{`Liczba zgłoszeń oczekujących na rozwiązanie: ${liczbaOczekujacych}`}</p>
                </div>
                <div className="usterki-lista">
                    <ul className="lista ramka-inset-shadow">
                        <li key={0}>
                            <div>ID</div>
                            <div>Zgłaszający</div>
                            <div>Data zgłoszenia</div>
                            <div>Kategoria</div>
                            <div>Podkategoria</div>
                            <div>Komentarz do zgłoszenia</div>
                            <div>Status</div>
                            <div>Data rozwiązania</div>
                            <div>Komentarz do rozwiązania</div>
                        </li>
                        {lista}
                    </ul>
                </div>
                {
                    activeUsterka != 0 ? (
                        <form 
                            onSubmit={handleSave}
                            className="ramka-border-shadow ramka-wnetrze-ramki"

                        >
                            <p>{`Formularz rozwiązywania usterki: ${activeUsterka}`}</p>
                            <label htmlFor="data">
                                Data rozwiązania:
                            </label>
                            <input 
                                type="date" 
                                id="data"
                                onChange={handleData}
                                value={data}
                            />
                            <label htmlFor="comment">
                                Komentarz do rozwiązania:
                            </label>
                            <textarea
                                id="comment"
                                cols={50}
                                rows={6}
                                onChange={handleComment}
                                value={comment}
                            />
                            <button>
                                Zapisz rozwiązanie
                            </button>
                        </form>
                    ) : <></>
                }
            </div>

        </motion.div>
    )
}

export default Usterki;