import React, { useEffect, useState } from "react";
import { useReactToPrint } from 'react-to-print';


const ZbiorowyWydrukButton = props => {

    const { componentRef, handleShowPrint } = props;

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Visitor Pass',
        onAfterPrint: () => console.log('Printed PDF successfully!'),
    });

    return (
        <div>
            <button
                onClick={handlePrint}
            >
                Drukuj
            </button>
            {/* <button
                onClick={handleShowPrint}
            >
                Anuluj
            </button> */}
        </div>
    )
}

export default ZbiorowyWydrukButton;