import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from "axios";
import { minusOneDay, dataFormat2, addOneDay } from '../../../functions/dataFormat';
import { fetchGrupy, fetchGrupyAkt, fetchGrupyAll, fetchWersje, fetchUdzialyKonta, fetchUdzialyKontaAkt, fetchUdzialyKontaAll, fetchLokale } from '../../../Redux/Actions';
import MieszkancyDaneEdytujGrupeMieszkanie from './MieszkancyDaneEdytujGrupeMieszkanie';
import MieszkancyDaneEdytujGrupeLokale from './MieszkancyDaneEdytujGrupeLokale';
import { adresPro, findMieszkanieId } from '../../../functions/ulice';
import { formatKonta } from '../../../functions/utilities';
import MieszkancyDaneDodajGrupeMieszkanie from '../MieszkancyDaneDodajGrupeMieszkanie';
import MieszkancyDaneEdytujGrupeStawkiInd from './MieszkancyDaneEdytujGrupeStawkiInd';

const MieszkancyListaEdytujGrupe = (props) => {

    const { editGrupe, setEditGrupe, ulice, lokale, grupy_u, udzialyKontaAkt_u, mieszkaniec, editDataOd, setEditDataOd } = props;

    const queryParams = new URLSearchParams(window.location.search)
    const idw = queryParams.get("idw");
    const dispatch = useDispatch();

    const [edytowanyUdzialKonto, setEdytowanyUdzialKonto] = useState([]);
    const [edytowaneGrupy, setEdytowaneGrupy] = useState([]);
    const [kontoBankowe, setKontoBankowe] = useState();
    const [liczbaDomownikow, setLiczbaDomownikow] = useState(0)
    const [dataOd, setDataOd] = useState(dataFormat2(new Date()));
    const [dataDo, setDataDo] = useState( dataFormat2(minusOneDay(new Date())) );
    const [mieszkanieAdres, setMieszkanieAdres] = useState("x");
    const [wybraneMieszkanie, setWybraneMieszkanie] = useState(0);
    const [wybraneLokale, setWybraneLokale] = useState([]);
    const [domownicy, setDomownicy] = useState(0); //na potrzeby ..DodajGrupeMieszkanie
    const [zamknijMieszkanie, setZamknijMieszkanie] = useState(false);
    const [wybraneLokaleUzytkowe, setWybraneLokaleUzytkowe] = useState([]);
    const [edytowaneGrupyUzytkowe, setEdytowaneGrupyUzytkowe] = useState([]);
    const [kontoValid, setKontoValid] = useState(true);

    useEffect( () => {
        if ( kontoBankowe?.replace(/\s+/g, '')?.length !== 26)
            setKontoValid(false);
        else
            setKontoValid(true)
    },[kontoBankowe])

    useEffect( () => setEdytowanyUdzialKonto( udzialyKontaAkt_u?.filter( u => u.id == editGrupe)[0] ), [editGrupe, udzialyKontaAkt_u]);
  
    useEffect( () => setEdytowaneGrupy( grupy_u?.filter( grupa => grupa.grupa_numer === edytowanyUdzialKonto?.grupa_numer ) ), [grupy_u,edytowanyUdzialKonto]);

    useEffect( () => {
        const temp = edytowaneGrupy?.filter( e => {
            const lokal = lokale.find(l => l.id == e.id_lokal);
            return lokal && lokal.rodzaj !== "mieszkanie"
        })
        setEdytowaneGrupyUzytkowe( temp )
    },[edytowaneGrupy, lokale]);

    useEffect( () => {
        const temp = wybraneLokale?.filter( w => {
            const lokal = lokale.find( l => l.id == w.id);
            return lokal && lokal.rodzaj !== "mieszkanie"
        })
        setWybraneLokaleUzytkowe(temp)
    },[wybraneLokale, lokale]);

    useEffect( () => {
        const temp = edytowaneGrupy?.map( e => {
            const tempLokal = lokale?.find( l => l.id == e.id_lokal)
            if (tempLokal)
                return tempLokal;
        } )
        setWybraneLokale(temp);
    },[edytowaneGrupy, lokale]);

    useEffect( () => {
        setKontoBankowe(edytowanyUdzialKonto?.konto_bankowe);
        
        if (edytowanyUdzialKonto?.czy_mieszkanie_w_grupie == 1) {
            setLiczbaDomownikow(edytowanyUdzialKonto?.liczba_domownikow);
            const id_mieszkanie = findMieszkanieId(edytowanyUdzialKonto,edytowaneGrupy,lokale);
            setMieszkanieAdres( adresPro(id_mieszkanie, lokale, ulice) )
        }
        
    },[edytowanyUdzialKonto, mieszkaniec,edytowaneGrupy,lokale ]);

    useEffect( () => dispatch(fetchWersje(grupy_u)),[grupy_u]);

    const czyZawieraObiekt = (tablica, szukanyObiekt) => tablica.some(obiekt => obiekt?.id === szukanyObiekt?.id);

    const usunObiekt = (tablica, szukanyObiekt) => tablica.filter(obiekt => obiekt.id !== szukanyObiekt.id);

    const handleLokalToggle = lokal => {

        if (czyZawieraObiekt(wybraneLokale,lokal)) {
            setWybraneLokale(usunObiekt(wybraneLokale, lokal))
        } else {
            setWybraneLokale([...wybraneLokale, lokal])
        }
    }

    const handleAnuluj = () => setEditGrupe(0);

    const handleKontoBankowe = e => setKontoBankowe(e.target.value); 

    const handleDate = e => {
        setDataOd(e);
        setDataDo(minusOneDay(e));
    }

    const handleSave = async () => {
        let liczbaZmian = 0;

        //ZMIANY KONTA LUB LICZBY MIESZKAŃCÓW
        if ( edytowanyUdzialKonto?.konto_bankowe !== kontoBankowe || edytowanyUdzialKonto?.liczba_domownikow !== liczbaDomownikow
             || edytowanyUdzialKonto?.czynsz_od !== dataOd || edytowanyUdzialKonto?.data_od !== dataOd
             || edytowanyUdzialKonto?.czy_mieszkanie_w_grupie == 1 && zamknijMieszkanie
             || edytowanyUdzialKonto?.czy_mieszkanie_w_grupie == 0 && wybraneMieszkanie > 0 ) {
            
            let czy_mieszkanie_w_grupie = edytowanyUdzialKonto.czy_mieszkanie_w_grupie;
            if (edytowanyUdzialKonto?.czy_mieszkanie_w_grupie == 1 && zamknijMieszkanie) {
                czy_mieszkanie_w_grupie = null;
            } else if (edytowanyUdzialKonto?.czy_mieszkanie_w_grupie != 1 && wybraneMieszkanie > 0) {
                czy_mieszkanie_w_grupie = 1;
            }

            console.log('X');
            liczbaZmian++;
            axios.post('/api/grupy_konta_udzialy/dodaj', {
                id_wspolnoty: edytowanyUdzialKonto.id_wspolnoty,
                id_user: edytowanyUdzialKonto.id_user,
                grupa_numer: edytowanyUdzialKonto.grupa_numer,
                konto_bankowe: kontoBankowe.replace(/\s+/g, ''),
                liczba_domownikow: liczbaDomownikow,
                data_od: dataOd,
                czynsz_od: edytowanyUdzialKonto?.czynsz_od,                
                czy_mieszkanie_w_grupie: czy_mieszkanie_w_grupie
            }).then( response => {
                dispatch(fetchUdzialyKonta());
                dispatch(fetchUdzialyKontaAkt());
                dispatch(fetchUdzialyKontaAll());        
            })
        }

        const grupyDoZmiany = grupy_u?.filter( g => g.data_od < dataOd)
        if (grupyDoZmiany?.length > 0) {
            liczbaZmian++;
            grupyDoZmiany?.forEach( grupa => {

                try {
                    axios.post('/api/grupy/edytuj_data_od', {
                        id: grupa?.id,
                        data_od: dataOd
                    }).then( response => {
                        dispatch(fetchGrupy());
                        dispatch(fetchGrupyAll());
                        dispatch(fetchGrupyAkt());
                    })
                } catch (error) {
                    console.log(error)
                }
            })          
        }

        //USUWANIE MIESZKANIA
        const mieszkanieId = findMieszkanieId(edytowanyUdzialKonto,edytowaneGrupy,lokale);
        if (edytowanyUdzialKonto?.czy_mieszkanie_w_grupie == 1 && zamknijMieszkanie) {
            console.log('A');
            liczbaZmian++;  
            try {
                await axios.post('/api/grupy/zamknij_data_do', {
                    id: edytowaneGrupy?.find( e => e.id_lokal == mieszkanieId).id,
                    data_do: dataOd
                }).then( response => {
                    dispatch(fetchGrupy());
                    dispatch(fetchGrupyAll());
                    dispatch(fetchGrupyAkt());
                })
            } catch (error) {
                console.log(error)
            }
            try {
                axios.post('/api/lokal/zwolnij', {
                    id_lokal: mieszkanieId
                }).then(response => {
                    dispatch(fetchLokale());
                })
            } catch (error) {
                console.log(error)
            }
        }
        //DODAWANIA MIESZKANIA
        else if (edytowanyUdzialKonto?.czy_mieszkanie_w_grupie == 0 && wybraneMieszkanie > 0 ) {
            console.log('B');
            liczbaZmian++;
            try {
                await axios.post('/api/grupy/dodaj', {
                    id_wspolnoty: idw,
                    id_user: edytowanyUdzialKonto.id_user,
                    grupa_numer: edytowanyUdzialKonto.grupa_numer,
                    id_lokal: wybraneMieszkanie,    
                    data_od: dataOd,
                    wersja: 1
                }).then( response => {
                    axios.post('/api/lokal/zajmij', {
                        id_lokal: mieszkanieId,    
                    }).then( response => {
                        dispatch(fetchLokale()); 
                    });
                })
            } catch (error) {
                console.log(error)
            }
        }
        
        const braki = edytowaneGrupyUzytkowe.filter(grupa => {
            return !wybraneLokaleUzytkowe.some(lokal => lokal.id_lokal === grupa.id_lokal);
        });

        if (braki?.length > 0) {
            console.log('C');
            liczbaZmian++;
            braki.forEach( (b, i) => {
                axios.post('api/grupa/zamknij', {
                    data_do: b.dataOd,
                    id: b.id
                }).then( response => {
                    if ( braki.length === i - 1 ) {
                        dispatch(fetchGrupy());
                        dispatch(fetchGrupyAll());
                        dispatch(fetchGrupyAkt());
                        axios.post('api/lokal/zwolnij', {
                            id_lokal: b.id_lokal
                        })
                    }
                })
            })
        }

        const nowe = wybraneLokaleUzytkowe.filter(grupa => {
            return !edytowaneGrupyUzytkowe.some(lokal => lokal.id_lokal === grupa.id_lokal);
        });

        if (nowe?.length > 0) {
            console.log('D');
            liczbaZmian++;
            nowe.forEach( (n, i) => {
                axios.post('/api/grupy/dodaj', {
                    id_wspolnoty: n.id_wspolnoty,
                    id_user: n.id_user,
                    grupa_numer: n.grupa_numer,
                    id_lokal: n.id_lokal,    
                    data_od: dataOd,
                    wersja: 0
                }).then( response => {
                    if ( nowe.length === i - 1 ) {
                        dispatch(fetchGrupy());
                        dispatch(fetchGrupyAll());
                        dispatch(fetchGrupyAkt());
                        axios.post('api/lokal/zajmij', {
                            id_lokal: n.id_lokal
                        })
                    }
                })
            })
        }
        if (!liczbaZmian)
            alert("Brak zmian do wprowadzenia");
        else 
            setEditGrupe(0); 
    }

    return (
        <div className='form ramka-br-light'>
            <div className='naglowek'>
                <h3>{ `Grupa numer: ${ edytowanyUdzialKonto?.grupa_numer }` }</h3>
            </div>
            <ul className="lista udzialKonto">
                <li>
                    <div>
                        Data obowiązywania zamian:
                    </div>
                    <div>
                        <input
                            type="date"
                            value={dataOd}
                            onChange={e=>handleDate(e.target.value)}
                        />
                    </div>
                </li>
                <li>
                    <div>
                        Id grupy:
                    </div>
                    <div>
                        {edytowanyUdzialKonto.id}
                    </div>
                </li>
                <li>
                    <div>
                        Numer konta:
                    </div>
                    <div>
                        <input 
                            type="text" 
                            style={{"width": "300px"}} 
                            value={formatKonta(kontoBankowe)}
                            onChange={ e => handleKontoBankowe(e)}
                            className={ kontoValid ? "" : "red" }
                        />
                    </div>
                </li>
                {
                    edytowanyUdzialKonto?.czy_mieszkanie_w_grupie === "1" ? (
                        <MieszkancyDaneEdytujGrupeMieszkanie 
                            liczbaDomownikow={liczbaDomownikow}
                            setLiczbaDomownikow={setLiczbaDomownikow}
                            mieszkanieAdres={mieszkanieAdres}
                            zamknijMieszkanie={zamknijMieszkanie}
                            setZamknijMieszkanie={setZamknijMieszkanie}
                        />
                    ) : (
                        <MieszkancyDaneDodajGrupeMieszkanie
                            setWybraneMieszkanie={setWybraneMieszkanie}
                            ulice={ulice}
                            lokale={lokale}
                            id_wspolnoty={idw}
                            liczbaDomownikow={liczbaDomownikow}
                            setLiczbaDomownikow={setLiczbaDomownikow}
                            domownicy={domownicy}
                            setDomownicy={setDomownicy}
                        />
                    )
                }

            </ul>
            
            <MieszkancyDaneEdytujGrupeLokale 
                wybraneLokale={wybraneLokale}
                ulice={ulice}
                lokale={lokale}
                edytowaneGrupy={edytowaneGrupy}
                edytowanyUdzialKonto={edytowanyUdzialKonto}
                handleLokalToggle={handleLokalToggle}
                rodzajLokalu={"garaż"}
                editDataOd={editDataOd}
                setEditDataOd={setEditDataOd}
            />

            <MieszkancyDaneEdytujGrupeLokale 
                wybraneLokale={wybraneLokale}
                ulice={ulice}
                lokale={lokale}
                edytowaneGrupy={edytowaneGrupy}
                edytowanyUdzialKonto={edytowanyUdzialKonto}
                handleLokalToggle={handleLokalToggle}
                rodzajLokalu={"strych"}
                editDataOd={editDataOd}
                setEditDataOd={setEditDataOd}
            />

            <MieszkancyDaneEdytujGrupeLokale 
                wybraneLokale={wybraneLokale}
                ulice={ulice}
                lokale={lokale}
                edytowaneGrupy={edytowaneGrupy}
                edytowanyUdzialKonto={edytowanyUdzialKonto}
                handleLokalToggle={handleLokalToggle}
                rodzajLokalu={"komórka"}
                editDataOd={editDataOd}
                setEditDataOd={setEditDataOd}
            />      

            <button onClick={handleSave}>
                Zapisz
            </button>

            <button onClick={handleAnuluj}>
                Anuluj
            </button>

            <MieszkancyDaneEdytujGrupeStawkiInd 
                edytowanyUdzialKonto={edytowanyUdzialKonto}
                setEditGrupe={setEditGrupe}
                idw={idw}
            />
        </div>
    )
}

export default MieszkancyListaEdytujGrupe;