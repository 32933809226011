import React, { useState, useEffect } from "react";
import axios from 'axios';
import { adresPro, findMieszkanieId } from '../../../functions/ulice';
import { useSelector, useDispatch } from 'react-redux';
import { dataFormat2 } from "../../../functions/dataFormat";
import { zl } from "../../../functions/utilities";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import DialogYesNo from "../../DialogYesNo.js";
import { fetchStawkiJednorazoweInd } from "../../../Redux/Actions";

const WspolnotyStawkiJednorazoweGrupyLista = props => {

    const { wspolnota, mieszkancy, lokale, ulice } = props;

    const dispatch = useDispatch();

    const stawkiJednorazoweInd = useSelector( state => state?.stawkiJednorazoweInd?.data);
    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);
    const grupy = useSelector( state => state?.grupy?.data);
    
    const [stawkiJednorazoweInd_w, setStawkiJednorazoweInd_w] = useState([]);
    const [open, setOpen] = useState(false);
    const [trashId, setTrashId] = useState(0);

    useEffect( () => setStawkiJednorazoweInd_w( stawkiJednorazoweInd?.filter( s => s?.id_wspolnoty == wspolnota?.id) ),  [stawkiJednorazoweInd])
    
    const handleClick = id => {
        setTrashId(id)
        setOpen(true);  
    }

    async function usunStawke(id) {
        try {
          const response = await axios.post('/api/wspolnoty_stawki_jednorazowe_ind/usun', { id: id });
          if (response.data.success === true)  {
            dispatch(fetchStawkiJednorazoweInd()); 
          }

          console.log('Response from backend:', response.data);
        } catch (error) {
          console.error('Error sending ID to backend:', error);
        }
    }

    const lista = stawkiJednorazoweInd_w?.map( (stawka, i) => {

        const mieszkaniec = mieszkancy?.find( m => m?.id_user == stawka?.id_user);
        const udzialKontaAkt = udzialyKontaAkt?.find( u => u?.id_user == stawka?.id_user && u?.grupa_numer == stawka?.grupa_numer )
        const grupy_m = grupy?.filter( g => g?.id_user == stawka?.id_user && g.grupa_numer == stawka?.grupa_numer)
        const mieszkanieId = findMieszkanieId(udzialKontaAkt, grupy_m, lokale);
        const mieszkanie = adresPro(mieszkanieId, lokale, ulice)

        return (
            <li key={i}>
                <div>
                    {`${mieszkaniec?.imie_nazwisko} (${mieszkaniec?.id_user})`}
                </div>
                <div className="central">
                    {stawka?.grupa_numer}
                </div>
                <div>
                    {mieszkanie}
                </div>
                <div>
                    {stawka?.msc}
                </div>
                <div>
                    {stawka?.opis}
                </div>
                <div>
                    {zl(stawka?.kwota)}
                </div>
                <div className="central">
                    <FontAwesomeIcon 
                        icon={faTrash} 
                        className="pointer" 
                        style={{"color": "red"}} 
                        onClick={()=>handleClick(stawka.id)}
                    /> 
                </div>
            </li>
        )
    })


    return (
        <div className="stawkiJednorazoweGrupyLista">
            <DialogYesNo 
                open={open}
                setOpen={setOpen}
                message={`Czy na pewno chcesz usunąć tę stawkę?`}
                f={usunStawke}
                id={trashId}
            />
            <h3>
                Lista stawek jednorazowych indywidualnych
            </h3>
            <ul className="lista">
                <li key={0}>
                    <div>
                        Mieszkaniec
                    </div>
                    <div className="central">
                        Numer grupy
                    </div>
                    <div>
                        Lokal
                    </div>
                    <div>
                        Miesiąc
                    </div>
                    <div>
                        Opis
                    </div>
                    <div>
                        Kwota
                    </div>
                    <div className="central">
                        Usuń
                    </div>
                </li>
                { lista }
            </ul>
        </div>
    )
}

export default WspolnotyStawkiJednorazoweGrupyLista;
