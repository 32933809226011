import React, {useContext, useState, useEffect} from "react";
import { NavLink, useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import { menuLi } from "../functions/motions";
import '../scss/style.css';
import '../scss/navigation.css';
import axios from "axios";
import { AuthContext } from '../Context/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket, faMagnifyingGlass, faKey, faDroplet, faPeopleRoof, faLandmark, faBullhorn, faCheckToSlot } from '@fortawesome/free-solid-svg-icons'
import { wstepy } from '../functions/motions';

import { 
    fetchMieszkancy, 
    fetchUsers,
  } from '../Redux/Actions.js'; 

const Navigation = () => {
    const navigate = useNavigate();
    const { auth, user, setAuth, role, setRole, userId, cred } = useContext(AuthContext);
    const [ isFocus, setFocus ] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const dispatch = useDispatch();

    const [user_id, setUser_id] = useState([]);

    useEffect( ()=> {
        dispatch(fetchMieszkancy()); 
        dispatch(fetchUsers());
      },[dispatch]);

    const mieszkancy = useSelector( state => state?.mieszkancy?.data);
    const users = useSelector( state => state?.users?.data);

    useEffect( () => {
        setUser_id( users?.find( u => u.user == user)?.id )
    },[users, user])

    const [mieszkaniec, setMieszkaniec] = useState();

    useEffect( () => {
        setMieszkaniec( mieszkancy?.find( m => m.id_user == user_id) )
    },[mieszkancy, user_id]);


    const [showLens, setShowLens] = useState(false);
    const handleLens = () => setShowLens(prevState => !prevState);
      
    const logout = () => {
        axios.get("/api/logout")
            .then( res => {
                if (res.data.Status === "Success") {
                    // navigate('/');
                    window.location.reload(false);
                } else {
                    alert("error");
                }
            }).catch(err => console.log(err));
    }

    const uniqueUsersMap = new Map();
    mieszkancy?.forEach(user => {
      if (!uniqueUsersMap.has(user.id_user) || new Date(user.data_od) > new Date(uniqueUsersMap.get(user.id_user).data_od)) {
          uniqueUsersMap.set(user.id_user, user);
      }
    });
    const uniqueUsers = Array.from(uniqueUsersMap.values());
    
    const openHint = ( (id, idw) => {
        setFocus(false);
        navigate(`/mieszkancy?id=${id}&idw=${idw}`);
    });

    const clickLogo = () => navigate(`/`);

    return(
        <motion.nav
            initial={{ y: -200 }}
            animate={{ y: 0 }}
            transition={{ duration: .5 }}
        >
            <div className="main">
                <div className="logo pointer" onClick={clickLogo}>
                    {/* <img src={logo}  alt="logo"></img> */}
                </div>
                <div className="menu">
                    <ul>
                        <motion.li 
                            whileHover={menuLi}
                        >
                            <Link to="https://bonadministrator.pl" target={"_blank"} > {"Strona główna"} </Link> 
                        </motion.li>
                        <motion.li
                            whileHover={menuLi}
                        >
                            <Link to="https://bonadministrator.pl/o-nas" target={"_blank"}> {"O Nas"} </Link> 
                        </motion.li>
                        <motion.li
                            whileHover={menuLi}
                        >
                            <Link to="https://bonadministrator.pl/oferta" target={"_blank"}> {"Oferta"} </Link> 
                        </motion.li>
                        <motion.li 
                            whileHover={menuLi}
                        >
                            <Link to="https://bonadministrator.pl/kontakt" target={"_blank"} > {"Kontakt"} </Link> 
                        </motion.li>
                        <motion.li 
                            whileHover={menuLi}
                        >
                            <Link to="https://bonadministrator.pl/ogloszenia" target={"_blank"}> {"Ogłoszenia"} </Link> 
                        </motion.li>
                        <motion.li 
                            whileHover={menuLi}
                        >
                            <Link to="/komunikaty"> {"Komunikaty"} </Link> 
                        </motion.li>
                        {role === "mieszkaniec" ? (
                            <motion.li 
                                whileHover={menuLi}
                            >
                                <NavLink to="/mieszkaniec"> {"Moje dane"}  </NavLink> 

                            </motion.li> ) : ""
                        }
                        {mieszkaniec?.czy_zarzad == 1 ? (
                            <>
                                <motion.li 
                                    whileHover={menuLi}
                                >
                                    <NavLink to="/raport-zbiorczy"> {"Raport zbiorowy"}  </NavLink> 

                                </motion.li> 
                            </>
                            
                        ) : ""}
                        {role === "admin" ? (
                            <>                        
                                <motion.li 
                                    whileHover={menuLi}
                                    style={{marginLeft: "50px"}}
                                >
                                    <NavLink to="/wspolnoty">
                                        <FontAwesomeIcon 
                                            icon={faPeopleRoof} 
                                            className="pointer"
                                            style={{padding: '5px 10px'}}
                                        />  
                                    </NavLink> 
                                </motion.li>
                                <motion.li 
                                    whileHover={menuLi}
                                >
                                    <NavLink to="/woda"> 
                                        <FontAwesomeIcon 
                                            icon={faDroplet} 
                                            className="pointer"
                                            style={{padding: '5px 10px'}}
                                        />   
                                    </NavLink> 
                                </motion.li>
                                <motion.li 
                                    whileHover={menuLi}
                                >
                                    <NavLink to="/ksiegowosc"> 
                                        <FontAwesomeIcon 
                                            icon={faLandmark} 
                                            className="pointer"
                                            style={{padding: '5px 10px'}}
                                        />   
                                    </NavLink> 
                                </motion.li>
                                <motion.li 
                                    whileHover={menuLi}
                                >
                                    <NavLink to="/usterki">                                     
                                        <FontAwesomeIcon 
                                            icon={faBullhorn} 
                                            className="pointer"
                                            style={{padding: '5px 10px'}}
                                        />   
                                    </NavLink> 
                                </motion.li>
                                <motion.li 
                                    whileHover={menuLi}
                                >
                                    <NavLink to="/glosowanie">                                     
                                        <FontAwesomeIcon 
                                            icon={faCheckToSlot} 
                                            className="pointer"
                                            style={{padding: '5px 10px'}}
                                        />   
                                    </NavLink> 
                                </motion.li>
                                <motion.li 
                                    whileHover={menuLi}
                                >
                                    <NavLink to="/logi">                                     
                                        <FontAwesomeIcon 
                                            icon={faKey} 
                                            className="pointer"
                                            style={{padding: '5px 10px'}}
                                        />   
                                    </NavLink> 
                                </motion.li>
                                <motion.li>
                                    <FontAwesomeIcon 
                                        icon={faMagnifyingGlass} 
                                        onClick={handleLens}
                                        className="pointer"
                                        style={{padding: '5px 10px'}}
                                    />  
                                </motion.li>
                                <li>
                                    { showLens ? (
                                        <>
                                            <motion.input 
                                                className="searchMieszkaniec" 
                                                placeholder="Znajdź mieszkańca"
                                                onClick={()=>{setFocus(!isFocus)}}
                                                value={inputValue.toLowerCase()}
                                                onChange={(e)=>setInputValue(e.target.value.toLowerCase())}
                                                variants={wstepy}
                                                initial="initial"
                                                animate="animate"
                                            />
                                                {
                                                    isFocus && (
                                                        <motion.div 
                                                            className="hintList"
                                                            variants={wstepy}
                                                            initial="initial"
                                                            animate="animate"
                                                        >
                                                        {uniqueUsers?.map((mieszkaniec, i) => {
                                                            const input = mieszkaniec?.imie_nazwisko?.toLowerCase();
                                                            const isMatch = input.indexOf(inputValue) > -1
                                                            return (
                                                                isMatch && (
                                                                <div 
                                                                    key={i} 
                                                                    className="hint" 
                                                                    onClick={()=>openHint(mieszkaniec?.id_user, mieszkaniec?.id_wspolnoty)}
                                                                >
                                                                    <div>
                                                                        {mieszkaniec?.imie_nazwisko}
                                                                    </div>
                                                                </div>)
                                                            )
                                                        })}
                                                    </motion.div>
                                                    ) 
                                                }
                                        </>

                                    ) : ""}
                                </li>



                            </>
                        ) : "" }
                        { auth == false || role==undefined? (
                            <motion.li 
                                whileHover={menuLi}
                            >
                                <NavLink to="/login" > {"Logowanie"} </NavLink> 
                            </motion.li>
                        ): 
                            ""
                        }                        
                    </ul>
                    <div>
                    </div>

                    { auth && role!=undefined? (
                        <button onClick={logout}> <FontAwesomeIcon icon={faRightFromBracket} /> </button>
                    ) : <div></div> }

                    
                </div>

            </div>

        </motion.nav>   
    )
}

export default Navigation;