import React, { useEffect, useState, useRef } from 'react';
import { obliczDatyNoty } from '../../functions/dataFormat.js';

import MieszkancyNotaButtons from './NotaButtons.js';

import NotaA4 from './NotaA4.js';

const NotaModal = (props) => {

    const { showPrint, mieszkaniec, wspolnota, lokale, nota, udzialGrupaNota, setLoaderOn, tablicaCzynsze } = props;


    const datyNoty = obliczDatyNoty();

    const componentRef = useRef();
    
    const handleModalClick = (event) => event.stopPropagation();
    
    return (
        <div className="mieszkaniecWydruk" onClick={showPrint}>
            <div className="modal" onClick={handleModalClick}>
                
                <NotaA4
                    componentRef={componentRef} 
                    wspolnota={wspolnota} 
                    mieszkaniec={mieszkaniec}
                    tablicaCzynsze={tablicaCzynsze?.find( t => t.id_wspolnoty == wspolnota?.id)}
                    udzialGrupaNota={udzialGrupaNota}
                    lokale={lokale}
                    datyNoty={datyNoty}
                />
                <MieszkancyNotaButtons
                    showPrint={showPrint}
                    componentRef={componentRef}
                    email={mieszkaniec?.email}
                    datyNoty={datyNoty}
                />

            </div>

        </div>
    )
}

export default NotaModal;