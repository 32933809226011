import React, { useEffect, useState} from "react";
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { mm_rrrr_to_date } from "../../../functions/dataFormat";
import { zl, isNumber } from "../../../functions/utilities";
import { adresPro } from "../../../functions/ulice";

const ZbiorowyWydrukTBody = props => {

    const { listaMsc, idw } = props;
    
    const location = useLocation();
    const { state } = location;

    const lokale = useSelector( state => state?.lokale?.data);
    const ulice = useSelector( state => state?.ulice?.data);
    const grupy = useSelector( state => state?.grupy?.data);
    const udzialyKonta = useSelector( state => state?.udzialyKonta?.data);
    const udzialyKontaAkt = useSelector( state => state?.udzialyKontaAkt?.data);

    const przelewyWspolnota = state?.from?.tablicaPrzelewy;
    const czynszeWspolnota = state?.from?.tablicaCzynsze;

    const mieszkancy = useSelector( state => state?.mieszkancy?.data);

    const [udzialyKontaAkt_w, setUdzialyKontaAkt_w] = useState([])

    useEffect( () => {
        setUdzialyKontaAkt_w( udzialyKontaAkt?.filter( udzial => udzial?.id_wspolnoty == idw))
    },[udzialyKonta, idw])

    const findGrupaZMieszkaniem = (udzialKonto, grupy, lokale) => {
        const grupy_u = grupy?.filter( g => g?.id_user == udzialKonto?.id_user && g?.grupa_numer == udzialKonto?.grupa_numer );
        const wynik = grupy_u.filter(grupa => {
            const lokal = lokale.find(lokal => lokal.id === grupa.id_lokal);
            return lokal && lokal.rodzaj === "mieszkanie";
          });
        return wynik;          
    }

    
    let sumaNaleznosciTotal = 0, sumaPrzelewyTotal = 0;
    const tab = udzialyKontaAkt_w?.map( (udzial, i) => {

        const mieszkaniec = mieszkancy.find( mieszkaniec => mieszkaniec.id_user == udzial.id_user);

        const grupaMieszkanie = findGrupaZMieszkaniem(udzial, grupy, lokale)[0];

        const grupyMsc = czynszeWspolnota?.find( czynsz => czynsz?.id_user == udzial?.id_user).grupy_msc?.find( c => c?.grupa_numer == udzial.grupa_numer);
        // const czynsz_na_msc = grupyMsc?.temp_msc?.find( g => g?.msc == miesiac)?.czynsz_na_msc


        

        const miesiace = listaMsc?.map( msc => {
            
            const czynszTemp = grupyMsc?.temp_msc?.find( g => g?.msc == msc)?.czynsz_na_msc;
            const czynszLast = grupyMsc?.temp_msc[grupyMsc?.temp_msc?.length -1]?.czynsz_na_msc;

            const czynsz_na_msc = czynszTemp === undefined && mm_rrrr_to_date(msc) >= mm_rrrr_to_date(grupyMsc?.temp_msc[grupyMsc?.temp_msc?.length -1].msc) ? czynszLast : czynszTemp
            // sumaNaleznosciTotal += isNumber(czynsz_na_msc) && (czynsz_na_msc > 0 || czynsz_na_msc < 0 || czynsz_na_msc == 0) ? czynsz_na_msc : 0;

            sumaNaleznosciTotal += isNumber(czynsz_na_msc) ? czynsz_na_msc : 0;
            console.log(sumaNaleznosciTotal)
            const przelewy = przelewyWspolnota?.find( p => p?.id_user == udzial?.id_user && p?.nr_grupyUdzial == udzial?.grupa_numer)?.przelewy;

            let sumaPrzelewy = 0;

            const przelewyMsc = przelewy?.filter( p => p?.msc == msc)
            
            sumaPrzelewy += przelewyMsc?.reduce((acc, obj) => {
                return acc + ( isNumber(obj.kwota) ? obj.kwota : 0 );
            },0);
            sumaPrzelewyTotal += sumaPrzelewy;
            
            return (
                <>
                    <td style={{whiteSpace: "nowrap", border: "1px solid black" }}>{`${zl(czynsz_na_msc)}`}</td>
                    <td style={{whiteSpace: "nowrap", border: "1px solid black" }}>{zl(sumaPrzelewy)}</td>
                    <td style={{fontWeight: "600", whiteSpace: "nowrap", border: "1px solid black" }}>{ zl(sumaPrzelewy - czynsz_na_msc)}</td>
                </>
            )
        })

        const podsumowanie = (
            <>
                <td style={{whiteSpace: "nowrap", border: "1px solid black" }}>{zl(sumaNaleznosciTotal)}</td>
                <td style={{whiteSpace: "nowrap", border: "1px solid black" }}>{zl(sumaPrzelewyTotal)}</td>
                <td style={{fontWeight: "600", border: "1px solid black", whiteSpace: "nowrap" }}>{ zl(sumaPrzelewyTotal - sumaNaleznosciTotal)}</td>
            </>
        )
        sumaNaleznosciTotal = 0;
        sumaPrzelewyTotal = 0;
        return (   
            <tr>
                <td style={{whiteSpace: "nowrap", border: "1px solid black"}}>
                    {`${mieszkaniec?.imie_nazwisko}`}
                </td>
                <td style={{whiteSpace: "nowrap", border: "1px solid black" }}>
                    {`${adresPro(grupaMieszkanie?.id_lokal, lokale, ulice)}`}
                </td>
                    <>
                        {miesiace}
                        {podsumowanie}
                    </>
            </tr>
        )
    }) 

    return (
        <tbody>
            {tab}
        </tbody>
    )
}

export default ZbiorowyWydrukTBody;