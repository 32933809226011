import React, { useEffect, useState } from 'react';
import { zl, filterZeroValues } from '../../../functions/utilities.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import { wstepy } from '../../../functions/motions.js';
import { hasKey, usunKluczNaN, filterKeysWithNumbers, arrayHasElement } from '../../../functions/tablice.js';

const MieszkaniecRozliczenieList = props => {

    const { tablicaCzynsze } = props;

    

    const [czynszeMsc, setCzynszeMsc] = useState([]);
    const [czynszeMscBezNan, setCzynszeMscBezNan] = useState([]);
    const [headers, setHeaders] = useState([]);


    useEffect( () => {
        if (czynszeMsc && czynszeMsc?.length) {
            const temp = filterKeysWithNumbers(czynszeMsc);
            const zredukowanaTablica = temp.reduce((acc, obj) => {
                Object.keys(obj).forEach(key => {
                    if (!acc.includes(key)) {
                        acc.push(key);
                    }
                });
                return acc;
            }, []);
            setHeaders(zredukowanaTablica)
        }
            // setHeaders( filterKeysWithNumbers(czynszeMsc)[0] )
    },[czynszeMsc])
  
    useEffect( () => {
        const filteredTemp = filterZeroValues(tablicaCzynsze?.temp_msc, ['msc']);
        setCzynszeMsc( filteredTemp )
    },[tablicaCzynsze]); 

    const [show, setShow] = useState(false);
    const onClickHandle = () => setShow( prevState => !prevState);

    useEffect( ( ) => {
        setCzynszeMscBezNan( usunKluczNaN(czynszeMsc) );
    },[czynszeMsc]);   

    const tab = czynszeMsc?.map( (msc,i) => {
        return (
            <tr key={i}>
                <td >{ msc?.msc }</td>
                { arrayHasElement(headers, "eksploatacja") ? (<td>{ zl(msc?.eksploatacja) }</td>) : ""}
                { arrayHasElement(headers, "garaz") ? (<td>{ zl(msc?.garaz) }</td>) : "" }
                { arrayHasElement(headers, "strych") ? (<td>{ zl(msc?.strych) }</td>) : ""}
                { arrayHasElement(headers, "komorka") ? (<td>{ zl(msc?.komorka) }</td>) : ""}
                { arrayHasElement(headers, "utrzymanie_czystosci") ? (<td>{ zl(msc?.utrzymanie_czystosci) }</td>) : ""}
                { arrayHasElement(headers, "wywoz_smieci") ? (<td>{ zl(msc?.wywoz_smieci) }</td>) : ""}
                { arrayHasElement(headers, "fundusz_remontowy") ? (<td>{ zl(msc?.fundusz_remontowy) }</td>) : ""}
                { arrayHasElement(headers, "konserw_domofonu") ? (<td>{ zl(msc?.konserw_domofonu) }</td>) : ""}
                { arrayHasElement(headers, "konserw_windy") ? (<td>{ zl(msc?.konserw_windy) }</td>) : ""}
                { arrayHasElement(headers, "opl_za_adm") ? (<td>{ zl(msc?.opl_za_adm) }</td>) : ""}
                { arrayHasElement(headers, "abonament_cw") ? (<td>{ zl(msc?.abonament_cw)}</td>) : ""}
                { arrayHasElement(headers, "co") ? (<td>{ zl(msc?.co) }</td>) : ""}
                { arrayHasElement(headers, "energia_el_lokalu") ? (<td>{ zl(msc?.energia_el_lokalu) }</td>) : ""}
                { arrayHasElement(headers, "ryczalt_gaz") ? (<td>{ zl(msc?.ryczalt_gaz) }</td>) : ""}
                { arrayHasElement(headers, "zagosp_terenu") ? (<td>{ zl(msc?.zagosp_terenu) }</td>) : ""}
                { arrayHasElement(headers, "antena") ? (<td>{ zl(msc?.antena) }</td>) : ""}
                { arrayHasElement(headers, "ciepla_woda") ? (<td>{ zl(msc?.ciepla_woda) }</td>) : ""}
                { arrayHasElement(headers, "zimna_woda") ? (<td>{ zl(msc?.zimna_woda) }</td>) : ""}
                { arrayHasElement(headers, "dod_1") ? (<td>{ zl(msc?.dod_1) }</td>) : ""}
                { arrayHasElement(headers, "dod_2") ? (<td>{ zl(msc?.dod_2) }</td>) : ""}
                { arrayHasElement(headers, "dod_3") ? (<td>{ zl(msc?.dod_3) }</td>) : ""}
                { arrayHasElement(headers, "czynsz_na_msc") ? (<td>{ zl(msc?.czynsz_na_msc) }</td>) : ""}
            </tr>
        )
    })  
    
    const tr = () => (
        <tr>
            <th>Miesiąc</th>
                { arrayHasElement(headers, "eksploatacja") ?   (<th>eks.</th>) : "" }
                { arrayHasElement(headers, "garaz") ?   (<th>gar.</th>) : "" }
                { arrayHasElement(headers, "strych") ?   (<th>str.</th>) : "" }
                { arrayHasElement(headers, "komorka") ?   (<th>kom.</th>) : "" }
                { arrayHasElement(headers, "utrzymanie_czystosci") ?   (<th>utr.</th>) : "" }
                { arrayHasElement(headers, "wywoz_smieci") ?   (<th>wyw.</th>) : "" }
                { arrayHasElement(headers, "fundusz_remontowy") ?   (<th>rem.</th>) : "" }
                { arrayHasElement(headers, "konserw_domofonu") ?   (<th>dom.</th>) : "" }
                { arrayHasElement(headers, "konserw_windy") ?   (<th>win.</th>) : "" }
                { arrayHasElement(headers, "opl_za_adm") ?   (<th>adm.</th>) : "" }
                { arrayHasElement(headers, "abonament_cw") ?   (<th>abo.</th>) : "" }
                { arrayHasElement(headers, "co") ?   (<th>co</th>) : "" }
                { arrayHasElement(headers, "energia_el_lokalu") ?   (<th>ene.</th>) : "" }
                { arrayHasElement(headers, "ryczalt_gaz") ?   (<th>gaz</th>) : "" }
                { arrayHasElement(headers, "zagosp_terenu") ?   (<th>zag.</th>) : "" }
                { arrayHasElement(headers, "antena") ?   (<th>ant.</th>) : "" }
                { arrayHasElement(headers, "ciepla_woda") ? ( <th>w. ciepła</th>) : "" }
                { arrayHasElement(headers, "zimna_woda") ?   (<th>w. zimna</th>) : "" }
                { arrayHasElement(headers, "dod_1") ? (<th>dod. 1</th>) : "" }
                { arrayHasElement(headers, "dod_2") ? (<th>dod. 2</th>) : "" }
                { arrayHasElement(headers, "dod_3") ? (<th>dod. 3</th>) : "" }
            <th>Suma</th>
        </tr>
    )
    

    return (
        <div>        
            <h4
                onClick={onClickHandle}
                className='pointer'
            >
                Historia należności { show ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} /> }
            </h4>   
            {
                show ? (
                    <motion.table 
                        className="lista tabela naleznosci"
                        variants={wstepy}
                        initial="initial"
                        animate="animate"
                    >
                        <thead>
                            {tr()}
                        </thead>
                        <tbody>
                            {tab}
                        </tbody>
                    </motion.table>  
                ) : ""
            }
        </div>
    )
}

export default MieszkaniecRozliczenieList;