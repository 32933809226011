import React from 'react';
import axios from "axios";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

import { useReactToPrint } from 'react-to-print';

const NotaButtons = (props) => {

    const { showPrint, componentRef, email, datyNoty} = props;

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Visitor Pass',
        onAfterPrint: () => console.log('Printed PDF successfully!'),
    });

    const generatePdf = async () => {
      const element = componentRef.current;
      
      // Użycie html2canvas do wygenerowania obrazu z elementu HTML
      const canvas = await html2canvas(element, { scale: 2 });
      const imgData = canvas.toDataURL('image/png');
  
      // Tworzenie nowego dokumentu PDF
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  
      // Dodanie obrazu do PDF
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  
      // Zapisanie PDF jako blob i wysłanie na backend
      const pdfBlob = pdf.output('blob');
      const formData = new FormData();
      formData.append('pdf', pdfBlob, 'document.pdf');
      formData.append('email', email)
      formData.append('miesiac', datyNoty?.miesiacNaliczenia)
  
      try {
        // Wysłanie PDF na backend
        const response = await axios.post('/api/send-pdf', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
  
        if (response.data.success) {
          alert('PDF został wygenerowany i wysłany e-mailem!');
        } else {
          alert('Wystąpił problem z wysyłaniem PDF.');
        }
      } catch (error) {
        console.error('Błąd podczas wysyłania PDF:', error);
      }
  };

  return (
    
    <div className="buttons">
        <button 
            onClick={handlePrint}
        >
            Drukuj
        </button>
        <button
            onClick={showPrint}
        >
            Anuluj
        </button>

        <button onClick={generatePdf}>Wyślij PDF e-mailem</button>
    </div>
  )
}

export default NotaButtons;