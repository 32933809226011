import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { copyToClipboard } from '../../functions/utilities.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import DialogYesNo from '../DialogYesNo.js';
import emailjs from '@emailjs/browser';
import axios from "axios";

import { fetchUserPasswordSend } from '../../Redux/Actions.js'; 


const MieszkancyDaneInformacjeWsad = props => {

    const { mieszkaniec, user } = props;

    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [history, setHistory] = useState({});

    const handleSendPwd = () => setOpen(true);

    const userPasswordSend = useSelector( state => state?.userPasswordSend?.data);

    useEffect( () => {
        dispatch(fetchUserPasswordSend());
    },[dispatch]);

    useEffect( () => {
        setHistory( userPasswordSend?.find( u => u.id_user == user?.id) )
    },[userPasswordSend, mieszkaniec, user]);

    const templateParams = {
        client: mieszkaniec?.email,
        login: mieszkaniec?.telefon,
        password: user?.password,
    }

    const sendMail = () => {
        emailjs.send('service_d6ugz9r', 'template_rgzgc5s', templateParams, 'Sf5FCcGAApPYZnnXi').then(
            (response) => {
                console.log('SUCCESS!', response.status, response.text);
                sendLog();                
            },
            (error) => {
              console.log('FAILED...', error);
            },
        );
    }  

    const sendLog = async () => {
        try {
            await axios.post('/api/password/send', {
                id_user: mieszkaniec?.id_user, 
                email: mieszkaniec?.email
            }).then(response => {
                if (response.status === 200) {
                    dispatch(fetchUserPasswordSend()); 
                }
            });
        } catch (error) {
            console.error('Błąd podczas dodawania danych:', error);
        }
    } 
    
    return (
        <ul className="lista mieszkancyDane">
            <DialogYesNo
                open={open}
                setOpen={setOpen}
                message="Czy na pewno chcesz wysłać e-mail z loginem i hasłem?"
                f={sendMail}
                id={templateParams}
            />
            <li>
                <div className='first'>Imię i nazwisko</div>
                <div>{`${mieszkaniec?.imie_nazwisko} (${mieszkaniec?.id_user})`}</div>
            </li>
            <li>
                <div className='first'>Telefon (login)</div>
                <div>{mieszkaniec?.telefon}</div>
            </li>
            <li>
                <div className='first'>E-mail</div>
                <div>{mieszkaniec?.email}</div>
            </li>
            <li>
                <div className='first'>Hasło</div>
                <div 
                    className='pwd'
                >   
                    <div
                        className='pointer'
                        onClick={copyToClipboard}
                    >
                        <span>
                            {user?.password}
                        </span>
                    </div>
                    {
                        mieszkaniec?.email ? (
                            <>
                                <div>
                                    <FontAwesomeIcon 
                                        icon={faPaperPlane} 
                                        className='pointer'
                                        onClick={handleSendPwd}
                                    />
                                </div>   
                                <div>
                                    { 
                                        history?.data_utworzenia ?(
                                            format(history?.data_utworzenia, 'yyyy-MM-dd') 
                                        ) : (
                                            `brak wysyłki`
                                        )
                                    }
                                </div>  
                            </>
                        ) : ""
                    }           
                </div>
            </li>
            <li>
                <div className='first'>Czy zarząd?</div>
                <div>{mieszkaniec?.czy_zarzad == 1 ? "Tak" : "Nie"}</div>
            </li>
            <li>
                <div className='first'>Czy nota?</div>
                <div>{mieszkaniec?.czy_nota == 1 ? "Tak" : "Nie"}</div>
            </li>
            {
                mieszkaniec?.czy_nota == 1 ? (
                    <>
                        <li>
                            <div className='first'>NIP</div>
                            <div>{mieszkaniec?.nip}</div>
                        </li>
                        <li>
                            <div className='first'>Nota - nazwa firmy</div>
                            <div>{mieszkaniec?.notaFirma}</div>
                        </li>
                        <li>
                            <div className='first'>Nota - adres</div>
                            <div>{mieszkaniec?.notaAdres}</div>
                        </li>
                        <li>
                            <div className='first'>Nota - adres</div>
                            <div>{mieszkaniec?.notaKodMiasto}</div>
                        </li>
                    </>
                ) : ""
            }
            <li>
                <div className='first'>Ostatnia aktualizacja</div>
                <div>{format(mieszkaniec?.data_od, 'yyyy-MM-dd')}</div>
            </li>
        </ul>
    )
}

export default MieszkancyDaneInformacjeWsad;