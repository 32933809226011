import React from 'react';

const MieszkaniecStawkiTHead = () => {

    return (
        <li className='firstRow'>
            <div>
                Tytuł zaliczki
            </div>
            <div>
                j.m.
            </div>
            <div>
                ilość
            </div>
            <div>
                cena j.
            </div>
            <div>
                Kwota zaliczki
            </div>
        </li>
    )
}

export default MieszkaniecStawkiTHead;