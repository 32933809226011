import React, { useState, useEffect } from 'react';
import { zl } from '../../../functions/utilities';

const MieszkaniecStawkiJednorazoweInd = props => {

    const { stawkiJednorazoweInd_msc } = props;

    const generateListItems3 = stawkiJednorazoweInd_msc?.map( (stawka, i) => (
        <li key={i}>
            <div>
                { stawka?.opis }
            </div>
            <div></div>
            <div></div>
            <div></div>
            <div>
                { zl(stawka?.kwota) }
            </div>
        </li>
    )) 
    
    return (
        <>
            {generateListItems3}
        </>
    )

}

export default MieszkaniecStawkiJednorazoweInd;