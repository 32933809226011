import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { motion } from "framer-motion";
import { wstepy } from "../../functions/motions";

import { fetchGlosowanieKarty, fetchGlosowaniePytania, fetchGlosowanieOdpowiedzi, fetchGlosowanieUchwaly, fetchWspolnoty,  } from "../../Redux/Actions";
import ListaWspolnot from "./ListaWspolnot";
import DodajKarte from "./DodajKarte";
import Przelgad from "./Przeglad";

const Glosowanie = () => {

    const dispatch = useDispatch();

    const [wspolnota, setWspolnota] = useState(null);
    const [activeWindow, setActiveWindow] = useState("");

    const [activeCard, setActiveCard] = useState(null);
    
    const wspolnoty = useSelector( state  => state?.wspolnoty?.data);
    
    const glosowanieKarty = useSelector( state => state?.glosowanieKarty?.data);
    const glosowaniePytania = useSelector( state => state?.glosowaniePytania?.data);
    const glosowanieOdpowiedzi = useSelector( state => state?.glosowanieOdpowiedzi?.data);
    const glosowanieUchwaly = useSelector( state => state?.glosowanieUchwaly?.data);

    // console.log(glosowanieUchwaly)

    useEffect( () => {
        glosowanieRefresh();
    },[dispatch]);

    const glosowanieRefresh = () => {
        dispatch(fetchWspolnoty());
        dispatch(fetchGlosowanieKarty());
        dispatch(fetchGlosowaniePytania());
        dispatch(fetchGlosowanieOdpowiedzi());
        dispatch(fetchGlosowanieUchwaly());
    }

    const window = () => {
        switch(activeWindow) {
            case "lista":
                return <Przelgad
                        glosowanieKarty={glosowanieKarty?.filter(g => g?.id_wspolnoty == wspolnota?.id)}
                        glosowaniePytania={glosowaniePytania?.filter(g => g?.id_wspolnoty == wspolnota?.id)}
                        glosowanieOdpowiedzi={glosowanieOdpowiedzi?.filter( g => g?.id_wspolnoty == wspolnota?.id)}
                        glosowanieUchwaly={glosowanieUchwaly?.filter( g => g?.id_wspolnoty == wspolnota?.id )}
                        wspolnota={wspolnota}
                        activeCard={activeCard}
                        setActiveCard={setActiveCard}
                    />
            case "dodaj":
            return  <DodajKarte 
                        wspolnota={wspolnota}
                        setActiveWindow={setActiveWindow}
                        glosowanieRefresh={glosowanieRefresh}
                    />
        }
    }

    const handlePrzeglad = () => setActiveWindow("lista");
    const handleNowe = () => setActiveWindow("dodaj")

    return (
        <motion.div 
            className="content ksiegowosc"
            variants={wstepy}
            initial="initial"
            animate="animate"
        >
            <h1 className="nazwa">
                Głosowania
            </h1>
            <div className="glosowanie">
                <ListaWspolnot
                    wspolnoty={wspolnoty}
                    wspolnota={wspolnota}
                    setWspolnota={setWspolnota}
                    setActiveWindow={setActiveWindow}
                    setActiveCard={setActiveCard}
                />
                {
                    wspolnota ? (
                        <div className="buttons">
                            <button onClick={handlePrzeglad}>
                                Przegląd głosowań
                            </button>
                            <button onClick={handleNowe}>
                                Dodaj nową kartę
                            </button>
                        </div>
                    ) : <></>
                }

                { wspolnota ? window() : "" }
            </div>
        </motion.div>
    )
}

export default Glosowanie;